<template>
  <div class="product">

    <img
      class="banner"
      :src="banner"
    >

    <div class="content">
      <h2>生产</h2>
      <p>
        我们公司采用的都是孚瑞美的设备，在国内同类型生产设备中孚瑞美设备更贵，但质量稳定，龙骨精度高。即使这样，我们仍会例行每半小时对龙骨校核，并修正设备累计误差，确保产品质量没问题。
      </p>
      <p>
        由于机器精准度高，很多现场施工人员都反应我们生产的龙骨拼装好后孔位精准，螺丝也很好打。其次，生产速度快，像一套200平方的房子，需要的龙骨大概是5000-6000米，一台机器一天之内就可以生产出来。
      </p>
      <h3>型材的生产</h3>
      <p>
        原材料的选择上我们使用的是攀钢和酒钢，在每一根型材上都会打印装配代码，我们使用专业级的工业电脑CNC数控机器。在每个型材上，机器会打印装配代码（在一侧）、钢材的批号和生产商名称（在另一侧）。同时我们对所有项目生产的型材都会做好严格的登记，包括生产日期、机器台号、原材料编号、型材起始（结束）编号、生产米数和生产人员、生产负责人等。这样可以对原材料进行溯源，且有利于保证产品质量做到有据可查。
      </p>
      <h3>包装</h3>
      <p>
        我们将型材按不同的长短规格进行打包，同时考虑到结构面板的构件组成。一个结构面板可能包含一个或多个包。工人们用包装带把型材妥妥的打包好，并进行临时的存储，然后根据客户的出货时间，再运到客户指定的项目所在地。
      </p>
      <h3>标签</h3>
      <p>
        我们还会在每个面板上贴上标签，它包含项目名称、结构面板名称和数量等，这样可以让现场施工的工人快速的找到每个结构部分的型材。
      </p>
    </div>
  </div>
</template>

<script>
import banner from '@/assets/banner/product.jpg'
export default {
  data() {
    return {
      banner
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  .banner {
    width: 100%;
    height: 40vw;
      object-fit: cover;

  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-size: 28px;
      font-weight: bold;
      color: #222222;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 22px;
      font-weight: bold;
      color: #222222;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      color: #666666;
      margin-bottom: 20px;
    }
  }
}
</style>
