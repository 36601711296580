import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VideoPlayer from 'vue-video-player'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import BaiduMap from 'vue-baidu-map'
import '@/style/index.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.use(VideoPlayer)
Vue.use(Vant)
Vue.use(BaiduMap, {
  ak: 'ynhLLnz5Ci7qzyEzicY26m0GP9LjdU3M' // ak 是在百度地图开发者平台申请的密钥
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
