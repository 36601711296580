<template>
  <!-- 下载 -->
  <div class="serve">
    <div class="bannerBox">

      <div class="txt">
        <h2>SERVICE SUPPORT</h2>
        <h3>服务支持</h3>
      </div>
    </div>
    <!-- 导航 -->
    <div class="topBox">
      <template v-for="(item, index) in classList">
        <div
          :key="index"
          class="btnBox"
          :class="{ active: item.component == currentTabComponent }"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <!-- 正文 -->
    <component :is="currentTabComponent" />
  </div>
</template>

<script>
import file from '@/views/serve/Download'
import matter from '@/views/serve/Question'
import { download } from '@/api/serve'

export default {
  components: {
    file,
    matter
  },
  data() {
    return {
      id: '',
      currentTabComponent: file,
      classList: [
        {
          name: '下载中心',
          component: file
        },
        {
          name: '常见问题',
          component: matter
        }
      ],
      downloadList: []
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: function(newV) {
        this.changeTab(newV.index)
      }
    }
  },
  created() {
    this.download()
  },
  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component
    },
    async download() {
      const res = await download()
      this.downloadList = res.data
      console.log(this.downloadList)
    },
    async dowmessage(index) {
      const currentFile = this.downloadList[index]
      const { file, name } = currentFile
      try {
        this.$dowmessage(file, name)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #018835 !important;
  color: #fff;
  font-weight: 800;
}
.serve {
  width: 100%;

  .bannerBox {
    margin-top: 70px;
    width: 100%;
    height: 40vw;
max-height: 200px;
    position: relative;
    background: url("~@/assets/banner/service.jpg") no-repeat center ;
    background-size: cover;

    .txt {
      position: absolute;
      top: 4vw;
      right: 5vw;
      h2 {
        font-size: 8vw;
        color: #000;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #018835;
        text-align: right;
      }
    }
  }

  .topBox {
    background-color: #f6f6f6;
    width: 100%;
    padding: 30px 0;
    color: #000;
    display: flex;
    justify-content: center;
    .btnBox {
      margin: 0 25px;
      width: 100px;
      font-size: 14px;
      height: 45px;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      transition: all 0.3s;

    }
  }
  .serviceBox {

    margin: 50px auto;
    .download {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px #eee dashed;
      margin: 10px 0;
      span {
        font-size: 15px;
      }
    }
  }
}
</style>
