<template>
  <!--我们的服务 -->
  <div class="serve">
    <div class="Box">
      <span class="Subtitle">OUR BUSINESS</span>
      <span class="Title">我们的业务</span>
    </div>
    <div class="serveContent">
      <div
        v-for="item in serveList"
        :key="item.id"
        class="serveContentSonBox"
      >
        <div class="img">
          <img
            :src="item.icon"
            alt=""
          >
        </div>
        <div class="txt">
          <h2 class="title">{{ item.name }}</h2>
          <span class="content">{{ item.sketch }}</span>
        </div>

        <hr>
      </div>

    </div>

  </div>
</template>

<script>
import { fetchServe } from '@/api/case'

export default {
  data() {
    return {
      serveList: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const res = await fetchServe()
      this.serveList = res.data
      console.log(this.serveList)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/index.scss";
.serve {
  width: 100%;
  background-color: #f6f6f6;

    .serveContent {
      padding: 0 20px;
      width: 100%;
      background-color: #f6f6f6;
      position: relative;
      display: flex;
      flex-direction: column;
      .serveContentSonBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        .img {
          width: 50%;
          img {
            width: 100%;
          }
        }
        @media only screen and (max-width: 600px) {
          .img {
            width: 100%;
          }
        }
        .txt {
          width: 50%;
          padding: 25px 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .title {
            font-size: 26px;
            font-weight: bold;
            color: #585858;
            text-align: justify;
            margin-bottom: 20px;
          }

          .content {
            font-size: 14px;
            line-height: 1.8;
            color: #686868;
          }
        }
        @media only screen and (max-width: 600px) {
          .txt {
            width: 100%;

          }
        }

    }
  }
}
</style>
