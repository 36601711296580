<template>
  <!-- 案例展示 -->
  <div class="case">
    <div class="bannerBox">
      <img src="@/assets/banner/case.jpg">
    </div>
    <!-- 正文 -->
    <div class="caseBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">类型：</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: btnindex == null }"
            @click="allClass"
          >
            全部
          </span>
          <template v-for="(item, index) in classBtnList">
            <span
              :key="item.id"
              :class="{ active: index == btnindex }"
              class="right"
              @click="onClassBtn(item.id, index)"
            >
              {{ item.caseClassName }}
            </span></template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">层高：</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: highindex == null }"
            @click="allHigh"
          >全部</span>
          <template v-for="(item, index) in caseHighList">
            <span
              :key="item.id"
              class="right"
              :class="{ active: index == highindex }"
              @click="onCaseHigh(item.id, index)"
            >
              {{ item.caseHighName }}
            </span>
          </template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">户型：</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: houseindex == null }"
            @click="allHouse"
          >全部</span>
          <template v-for="(item, index) in caseHouseList">
            <span
              :key="item.id"
              class="right"
              :class="{ active: index == houseindex }"
              @click="onCaseHouse(item.id, index)"
            >
              {{ item.caseHouseName }}
            </span>
          </template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">面积：</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: areaindex == null }"
            @click="allArea"
          >全部</span>
          <template v-for="(item, index) in caseAreaList">
            <span
              :key="item.id"
              class="right"
              :class="{ active: index == areaindex }"
              @click="onCaseArea(item.id, index)"
            >
              {{ item.caseAreaName }}
            </span>
          </template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">风格：</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: styleindex == null }"
            @click="allStyle"
          >全部</span>
          <template v-for="(item, index) in caseStyleList">
            <span
              :key="item.id"
              class="right"
              :class="{ active: index == styleindex }"
              @click="onCaseStyle(item.id, index)"
            >
              {{ item.caseStyleName }}
            </span>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <div class="detail">
        <template v-for="item in caseList">
          <router-link
            :key="item.id"
            class="caseItem"
            :to="{ name: 'CaseContent', query: { id: item.id } }"
          >
            <img class="up" :src="item.imgUrl">
            <div class="down">
              <div class="left">
                <span>{{ item.caseTitle }}</span>
                <time class="time">{{ item.description }}</time>
              </div>
              <div class="right">查看<br>详情</div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
    <!-- 分页按钮 -->

    <van-pagination
      v-model="currentPage"
      :page-count="allPage"
      :total-items="total"
      mode="simple"
      @change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  caseClass,
  caseInfo,
  caseHigh,
  caseHouse,
  caseArea,
  caseStyle
} from '@/api/case'

export default {
  data() {
    return {
      btnindex: null,
      highindex: null,
      houseindex: null,
      areaindex: null,
      styleindex: null,
      id: '',

      classBtnList: [],
      caseHighList: [],
      caseHouseList: [],
      caseAreaList: [],
      caseStyleList: [],
      caseList: [],
      limit: 6,
      classId: '',
      pages: 0,
      allPage: 0,
      total: 0
    }
  },
  async created() {
    await this.getData()
    await this.getClass()
    await this.caseHigh()
    await this.caseHouse()
    await this.caseArea()
    await this.caseStyle()
  },
  methods: {
    allClass() {
      this.btnindex = null
      this.classId = ''
      this.getData()
    },
    allHigh() {
      this.highindex = null
      this.highId = ''
      this.getData()
    },
    allHouse() {
      this.houseindex = null
      this.houseId = ''
      this.getData()
    },
    allArea() {
      this.areaindex = null
      this.areaId = ''
      this.getData()
    },
    allStyle() {
      this.styleindex = null
      this.styleId = ''
      this.getData()
    },

    handleCurrentChange(e) {
      this.pages = e
      this.getData()
      document.documentElement.scrollTop = 0
    },
    onClassBtn(id, index) {
      this.btnindex = index
      this.pages = ''
      this.classId = id
      this.getData()
    },
    onCaseHigh(id, index) {
      this.highindex = index
      this.pages = ''
      this.highId = id
      this.getData()
    },
    onCaseHouse(id, index) {
      this.houseindex = index
      this.pages = ''
      this.houseId = id
      this.getData()
    },
    onCaseArea(id, index) {
      this.areaindex = index
      this.pages = ''
      this.areaId = id
      this.getData()
    },
    onCaseStyle(id, index) {
      this.styleindex = index
      this.pages = ''
      this.styleId = id
      this.getData()
    },
    async getClass() {
      try {
        const classData = await caseClass()
        this.classBtnList = classData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseHigh() {
      try {
        const caseHighData = await caseHigh()
        this.caseHighList = caseHighData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseHouse() {
      try {
        const caseHouseData = await caseHouse()
        this.caseHouseList = caseHouseData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseArea() {
      try {
        const caseAreaData = await caseArea()
        this.caseAreaList = caseAreaData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseStyle() {
      try {
        const caseStyleData = await caseStyle()
        this.caseStyleList = caseStyleData.data
      } catch (error) {
        console.error(error)
      }
    },
    async getData() {
      try {
        const params = {
          caseClassId: this.classId,
          caseAreaId: this.areaId,
          caseHighId: this.highId,
          caseHouseId: this.houseId,
          caseStyleId: this.styleId,
          limit: this.limit,
          pages: this.pages
        }
        const infoData = await caseInfo(params)
        this.caseList = infoData.data.records
        this.total = infoData.data.total
        this.allPage = infoData.data.pages
        console.log(this.total)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  transition: all 0.5s;
  border-radius: 5px;
  background-color: #018835;
  color: #fff;
}
.case {
  width: 100%;
  .bannerBox {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .caseBox {
    width: 100%;
    margin: 40px auto;

    .classBtnBox {
      width: 90%;
      margin: 0 auto;
      border: 1px #e5e5e5 solid;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        min-width: 57px;
        padding: 10px;
        font-size: 12px;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::-webkit-scrollbar {
        display: none;
      }
      .a {
        background-color: #fff;
        display: flex;
        overflow-x: scroll;
        .right {
          margin: 0 10px 0 0;
          white-space: nowrap;
          padding: 5px;
        }
      }
    }

    .detail {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .caseItem {
        width: 46%;
        margin: 10px;
        box-shadow: 1px 1px 5px #888888;
        .up {
          width: 100%;
          object-fit: cover;
        }
        .down {
          display: flex;
          justify-content: space-between;
          padding: 15px;
          height: 90px;
          .left {
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 15px;
            color: #666;
          }
          .right {
            background-color: #018835;
            font-size: 15px;
            font-weight: 800;
            color: #fff;
            padding: 12px;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .caseItem {
          width: 100%;
        }
      }
    }
  }
  .van-pagination {
    width: 90%;
    margin: 0 auto 50px;
  }
}
::v-deep .van-pagination {
  .van-pagination__item {
    color: #323232;
  }
  .van-pagination__item:active {
    background-color: #018835;
  }
}
</style>
