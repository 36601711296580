// 产品接口
import request from '@/utils/request'

// 我们的业务
export function fetchServe(params) {
  return request({
    url: `/api/ourservices`,
    method: 'get',
    params
  })
}
// vr分类
export function vrClass(params) {
  return request({
    url: `/api/vrClass`,
    method: 'get',
    params
  })
}
// vr轮播图
export function vrbanner(params) {
  return request({
    url: `/api/vrInfo`,
    method: 'get',
    params
  })
}
// 案例分类
export function caseClass(params) {
  return request({
    url: `/api/caseClass`,
    method: 'get',
    params
  })
}
// 案例层高分类
export function caseHigh(params) {
  return request({
    url: `/api/caseHigh`,
    method: 'get',
    params
  })
}

// 案例面积分类
export function caseArea(params) {
  return request({
    url: `/api/caseArea`,
    method: 'get',
    params
  })
}

// 案例户型分类
export function caseStyle(params) {
  return request({
    url: `/api/caseStyle`,
    method: 'get',
    params
  })
}
// 案例风格分类
export function caseHouse(params) {
  return request({
    url: `/api/caseHouse`,
    method: 'get',
    params
  })
}

// 案例信息
export function caseInfo(params) {
  return request({
    url: `/api/caseInfo`,
    method: 'get',
    params
  })
}
// 案例详情
export function getCase(params) {
  return request({
    url: `/api/caseInfo/${params.id}`,
    method: 'get'
  })
}
