<template>
  <!-- 详情 -->
  <div class="main">
    <!-- 正文 -->
    <div class="mainBox">
      <div class="title">
        <h2>{{ caseData.name }}</h2>
        <span> 发行时间：{{ caseData.time.substring(0, 10) }}</span>
      </div>

      <div class="top-box">
        <div
          id="swiper"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <template v-for="(item, index) in casePics">
              <div
                :key="index"
                class="swiper-slide"
              >
                <img
                  class="pics"
                  :src="item.url"
                  alt=""
                >
              </div>
            </template>
          </div>
        </div>
        <div
          id="thumbs"
          class="thumbs"
        >
          <div class="swiper-wrapper">
            <template v-for="(item, index) in casePics">
              <div
                :key="index"
                class="swiper-slide"
              >
                <img
                  class="pics"
                  :src="item.url"
                  alt=""
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { photoDetail } from '@/api/home'
import Swiper from 'swiper'
export default {
  data() {
    return {
    casePics:[],
    caseData: [],
    }
  },

  async created() {
    this.id = this.$route.query.id
    await this.getCase()
    new Swiper('#swiper', {
      autoplay: true, // 可选选项，自动滑动
      loop: true,
      thumbs: {
        swiper: {
          el: '#thumbs', // 注意此处的设置方式

          slidesPerView: 5,
          watchSlidesVisibility: true
        },
        slideThumbActiveClass: 'active'
        // autoScrollOffset: 1
      }
    })
  },

  methods: {
    async getCase() {
      try {
        const { id } = this
        const params = {
          id
        }
        const { data } = await photoDetail(params)
        const casePics = data.url.split(";").map(item => ({ url: item }))
        this.caseData=data
        this.casePics=casePics
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #f3f3f3;
  padding: 20px;
  .mainBox {
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    background-color: #333;
    margin: 0 auto;
    // width: 1300px;
    padding: 20px 30px;
    .top-box {
      background-color: #333;
      margin: 20px auto 0;
      // width: 90%;
      // height: 700px;
      padding: 20px 0;
      overflow: hidden;

      .swiper {
        // width: 800px;
        // height: 500px;
        overflow: hidden;
        margin: 0 auto 30px;
        .pics {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .thumbs {
        .swiper-wrapper {
           height: 20vw;
          .swiper-slide {
              height: 10vw;
            width: 100%;
        overflow: hidden;
              margin: 0 10px;
            .pics {
              height: 100%;
              width: 100%;
              object-fit: cover;
              margin-bottom: 5px;
            }
          }
          .active {
            border: 3px solid #018835;
          }
        }
      }
    }
  }
}
</style>
