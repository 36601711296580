<template>
  <div class="bannerBox" :style="bannerStyles">
    <div class="txt">
      <h2 class="enTitle" :style="enTitleStyles">
        {{ enTitle }}
      </h2>
      <h2 class="cnTitle" :style="cnTitleStyles">
        {{ cnTitle }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerIndex',
  props: {
    backgroundImage: {
      type: String,
      default: ''
    },
    cnTitle: {
      type: String,
      default: ''
    },
    cnTitleColor: {
      type: String,
      default: '#000'
    },
    enTitle: {
      type: String,
      default: ''
    },
    enTitleColor: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {}
  },

  computed: {
    bannerStyles() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      }
    },

    enTitleStyles() {
      return {
        color: this.enTitleColor
      }
    },

    cnTitleStyles() {
      return {
        color: this.cnTitleColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerBox {
  position: relative;
  height: 40vw;
  font-size: 52px;
  background-size: cover;
  font-weight: bold;
  text-align: right;
  background-repeat: no-repeat;
  background-position:center;

  .txt {
    position: absolute;
    top: 5vw;
    right: 5vw;

    .enTitle {
      font-size: 8vw;
      color: #018835;
      font-weight: bold;
      text-align: right;
    }

    .cnTitle {
      font-size: 7vw;
      font-weight: bold;
      color: #fff;
      text-align: right;
    }
  }
}
</style>
