<template>
  <!-- 联系我们 -->
  <div class="main">
    <div class="content">
      <!-- 标题 -->
      <div class="Box">
        <span class="Chtitle">{{ joinList.name }}</span>
        <span>{{ joinList.time }}</span>
      </div>
      <div class="content">
        <span v-html="joinList.details" />
      </div>
    </div>
  </div>
</template>

<script>
import { join } from '@/api/about'

export default {
  data() {
    return {
      joinList: {}
    }
  },
  async created() {
    await this.join()
  },
  methods: {
    async join() {
      try {
        const params = {
          id: this.id
        }

        const res = await join(params)
        this.joinList = res.data[0]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/fontButton.scss";
.main {
  width: 100%;
  .content {

    margin: 0 auto;
    .Chtitle {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin-bottom: 15px;
    }
    .content {
      padding: 20px;
      border-top: 0.1px solid #eee;
      border-bottom: 0.1px solid #eee;
    }
  }
}
</style>
