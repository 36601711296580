// 视频接口
import request from '@/utils/request'

// 视频分类标题
export function videoClass(params) {
  return request({
    url: `/api/videoClass`,
    method: 'get',
    params
  })
}

// 视频内容
export function video(params) {
  return request({
    url: `/api/videoInfo`,
    method: 'get',
    params
  })
}
// 视频详情
export function getVideo(params) {
  return request({
    url: `/api/videoInfo/${params.id}`,
    method: 'get'
  })
}
// 播放量
export function videoInfo(params) {
  return request({
    url: `/api/videoInfo`,
    method: 'put',
    data: params
  })
}
