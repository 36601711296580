<template>
  <!-- 详情 -->
  <div class="content">
    <!-- 正文 -->
    <div class="contentBox">
      <div class="top-box ">
        <h3>{{ detail.name }}</h3>
        <div class="txt">
          <span>更新日期：{{ detail.time.substring(0,10) }}</span>
        </div>
        <div v-html="detail.content" />
      </div>
    </div>
  </div>
</template>

<script>
import { getpartner } from '@/api/about'
export default {
  data() {
    return {
      detail: {},
      id: ''
    }
  },

  created() {
    this.id = this.$route.query.id
    this.getpartner()
  },
  methods: {
    async getpartner() {
      try {
        const params = {
          id: this.id
        }
        const { data } = await getpartner(params)
        this.detail = data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: #f3f3f3;
  padding: 10px;
  width: 100%;
  .contentBox {
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    .top-box {
       padding: 30px 10px 0;
      h3 {
        font-size: 24px;
        color: #3d3d3d;
      }
      .txt {
        display: flex;
        justify-content: center;
        color: #878787;
        font-size: 14px;
        margin: 20px;
      }
    }

  }
}
</style>
