<template>
  <div class="flow">
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">SERVICE PROCESS</span>
      <span class="Title">服务流程</span>
    </div>

    <div class="content">
      <template v-for="(item, index) in picList">
        <div
          :key="index"
          class="pic"
          :class="{ isActive: currentIndex == index }"
          @mouseenter="mouseenter(index)"
        >
          <img :src="currentIndex == index ? item.imgUrls : item.imgUrl">
          <span>{{ item.txt }}</span>
        </div>
      </template>
      <div
        v-if="showDetail"
        class="detail"
      >
        <img
          :src="contentList[currentIndex].imgUrl"
          alt=""
        >

        <h2>{{ contentList[currentIndex].txt }}</h2>
        <span>{{ contentList[currentIndex].span }}</span>
        <router-link
          tag="div"
          to="/contactUs"
          class="button"
        >
          <p>联系我们</p>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const picList = [
      {
        imgUrl: require('@/assets/icon/lc1.png'),
        imgUrls: require('@/assets/icon/lc1s.png'),
        txt: '沟通需求，免费设计草图'
      },
      {
        imgUrl: require('@/assets/icon/lc2.png'),
        imgUrls: require('@/assets/icon/lc2s.png'),
        txt: '确认合作签约合同'
      },
      {
        imgUrl: require('@/assets/icon/lc3.png'),
        imgUrls: require('@/assets/icon/lc3s.png'),
        txt: '方案设计'
      },
      {
        imgUrl: require('@/assets/icon/lc4.png'),
        imgUrls: require('@/assets/icon/lc4s.png'),
        txt: '结构设计'
      },
      {
        imgUrl: require('@/assets/icon/lc5.png'),
        imgUrls: require('@/assets/icon/lc5s.png'),
        txt: '精准预算'
      },
      {
        imgUrl: require('@/assets/icon/lc6.png'),
        imgUrls: require('@/assets/icon/lc6s.png'),
        txt: '工厂生产'
      },
      {
        imgUrl: require('@/assets/icon/lc7.png'),
        imgUrls: require('@/assets/icon/lc7s.png'),
        txt: '交付余款运输配送'
      },
      {
        imgUrl: require('@/assets/icon/lc8.png'),
        imgUrls: require('@/assets/icon/lc8s.png'),
        txt: '材料验收售后服务'
      }
    ]
    const contentList = [
      {
        imgUrl: require('@/assets/lct1.jpg'),
        txt: '沟通需求，免费设计草图',
        span:
          '前期和销售沟通需求，公司可以配合客户出具简易方案草图，帮助客户恰谈业务。'
      },
      {
        imgUrl: require('@/assets/lct2.jpg'),
        txt: '确认合作签约合同',
        span: '确定合作意向和公司签订采购合同。'
      },
      {
        imgUrl: require('@/assets/lct3.jpg'),
        txt: '方案设计',
        span:
          '建立微信沟通群，设计师根据客户需求进行方案设计，公司也可单独承接建筑方案设计业务。'
      },
      {
        imgUrl: require('@/assets/lct4.jpg'),
        txt: '结构设计',
        span:
          '设计方案通过后，下一步进入结构设计，公司也可单独承接VT拆图、结构深化等。'
      },
      {
        imgUrl: require('@/assets/lct5.jpg'),
        txt: '精准预算',
        span: '结构设计完成后进入精准预算阶段。'
      },
      {
        imgUrl: require('@/assets/lct6.jpg'),
        txt: '工厂生产',
        span:
          '预算结束之后和客户核实无误，即开始安排生产，公司也可承接来料代加工业务。'
      },
      {
        imgUrl: require('@/assets/lct7.jpg'),
        txt: '交付余款运输配送',
        span: '龙骨生产完成，客户支付余款，工厂即可安排装车配送至项目所在地。'
      },
      {
        imgUrl: require('@/assets/lct8.jpg'),
        txt: '材料验收售后服务',
        span: '材料送达后业主对所有材料进行清点和签收，然后进入售后服务阶段。'
      }
    ]
    return {
      picList,
      contentList,
      showDetail: false,
      currentIndex: null
    }
  },

  methods: {
    mouseenter(index) {
      this.showDetail = true

      this.currentIndex = index
      console.log(this.picList[index].txt)
    }
  }
}
</script>

<style lang="scss" scoped>

.flow {
  margin-top: 10px;
  // height: 700px;
  background: linear-gradient(#fff, #018835);
  width: 100%;
}
.content {
  position: relative;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pic {
  position: relative;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100px;
  }
  @media only screen and (max-width: 500px) {
    img {
      width: 80%;
    }
  }
  span {
    padding: 0 10px;
    font-size: 13px;
    color: #fff;
    margin: 10px 0;
  }
}
.txt {
  position: absolute;
  top: 180px;
  background-color: rgb(255, 255, 255);
  height: 170px;
  width: 1200px;
}
.detail {
  width: 100%;
  margin: 3vw;
  background: rgb(255, 255, 255);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 100%;
  }

  h2 {
    font-size: 24px;
    color: #018835;
    margin: 20px 0;
  }
  span {
        margin: 20px 0;
    font-size: 16px;
    color: #5d5d5d;
  }
  .button {
    width: 200px;
    height: 40px;
    border: 2px solid #018835;
    text-align: center;
    font-size: 20px;
    line-height: 36px;
    color: #018835;
  }
}
</style>
