// 首页请求接口
import request from '@/utils/request'

//
// export function xxxx(params) {
//   return request({
//     url: `/api/xxxxx`,
//     method: 'get/post',
//     params
//   })
// }

// 轮播图
export function banner(params) {
  return request({
    url: `/api/banner`,
    method: 'get',
    params
  })
}
// 合影轮播图
export function photo(params) {
  return request({
    url: `/api/photoalbum`,
    method: 'get',
    params
  })
}
// 合影详情
export function photoDetail(params) {
  return request({
    url: `/api/photoalbum/${params.id}`,
    method: 'get'
  })
}

// vr轮播图
export function vrbanner(params) {
  return request({
    url: `/api/vrInfo`,
    method: 'get',
    params
  })
}
// vr分类
export function vrClass(params) {
  return request({
    url: `/api/vrClass`,
    method: 'get',
    params
  })
}
