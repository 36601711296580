<template>

  <!-- 发展历程 -->
  <div
    class="history"
  >
    <div class="headline">
      <h2>DEVELOPMENT HISTORY</h2>
      <h3>发展历程</h3>
    </div>

    <van-swipe
      class="my-swipe"
      :show-indicators="false"
      :loop="false"
    >
      <template v-for="(item,index) in picList">
        <van-swipe-item
          :key="index"
          class="swipe"
        >
          <div class="span sup">
            <h2>{{ item.txt }}</h2>
            <p>{{ item.span }}</p>
            <span>{{ item.number }}</span>
            <img :src="item.imgUrl">

          </div>

          <div
            class="span sub"
          >
            <img :src="item.imgUrl2">
            <h2>{{ item.txt2 }}</h2>
            <p>{{ item.span2 }}</p>
            <span>{{ item.number2 }}</span>
          </div>
        </van-swipe-item>
      </template>
    </van-swipe>

  </div>
</template>

<script>
export default {

  data() {
    const picList = [
      {

        imgUrl: require('@/assets/icon/fzlcz.png'),
        txt: '2018年5月',
        number: 1,
        span: '安雅园（广东）房屋科技有限公司成立',
        imgUrl2: require('@/assets/icon/fzlcz2.png'),
        txt2: ' 2018年5月',
        number2: 2,
        span2: '湖南长沙生产基地成立，拥有仓储面积5500平方，建立初始生产基地。'
      },

      {
        imgUrl: require('@/assets/icon/fzlcz3.png'),
        txt: '2019年6月',
        number: 3,
        span: '四川成都生产基地成立，仓储面积3000平方，开拓西南市场。',

        imgUrl2: require('@/assets/icon/fzlcz.png'),

        txt2: '2020年5月',
        number2: 4,
        span2: '湖南浏阳生产基地成立，仓储面积2000平方，稳固华中供应链。'
      },
      {
        imgUrl: require('@/assets/icon/fzlcz2.png'),
        txt: '2021年2月',
        number: 5,
        span: '广东佛山生产基地成立，仓储面积3000平方，进军华南市场。',

        imgUrl2: require('@/assets/icon/fzlcz3.png'),
        txt2: '2021年4月',
        number2: 6,
        span2: '浙江绍兴生产基地成立，仓储面积4000平方，逐步完善华东地区工厂布局。'
      },
      {
        imgUrl: require('@/assets/icon/fzlcz.png'),
        txt: '2021年5月',
        number: 7,
        span: '广东江门生产基地成立，仓储面积2000平方。',
        imgUrl2: require('@/assets/icon/fzlcz2.png'),
        txt2: '2021年7月',
        number2: 8,
        span2: '公司举办三周年庆典“暨行业客户合作交流会”。'
      }
    ]
    return {
      picList
    }
  }
}
</script>

<style lang="scss" scoped>
.history {
  background-image: url("~@/assets/fzlcbj.jpg");
  width: 100%;
  padding: 30px 10px;
}
.my-swipe {
  margin: 30px 10px;
  padding: 20px 0;
    background-color: #fff;
  .swipe {
    display: flex;
    justify-content: space-around;
    padding: 0 20px;
  }
  img {
    width: 100%;
  }
  .span {
    width: 100px;
    position: relative;

    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
      height: 70px;
    }
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
          font-size: 24px;
    color: #fff;
    }
  }
  .sub{
    padding-top: 113px;
  }
  .sup{
    padding-bottom: 113px;
  }

}
</style>
