<template>
  <!-- 案例展示 -->
  <div class="Materials">
    <Banner v-bind="bannerProps" />
    <!-- 正文 -->
    <div class="MaterialsBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">
          主材体系
        </div>
        <div class="a">
          <template v-for="item in mainMetailClasses">
            <div
              :key="item.id"
              class="b"
              :class="{ active: item.id == mainClassId }"
              @click="mainSelect(item.id)"
            >
              <span class="right">
                {{ item.materialsClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">
          辅材体系
        </div>
        <div class="a">
          <template v-for="item in assitantMetailClasses">
            <div
              :key="item.id"
              class="b"
              :class="{ active: item.id == assistClassId }"
              @click="assistSelect(item.id)"
            >
              <span class="right">
                {{ item.materialsClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <div id="routerhash" class="box">
        <template v-for="item in materialsList">
          <RouterLink
            :key="item.id"
            :to="{ path: '/MaterialsContent', query: { id: item.id } }"
          >
            <div class="big">
              <div class="left">
                <img class="img" :src="item.imgUrl" alt="">
              </div>
              <div class="right">
                <span class="sup"><img :src="item.logo" alt=""></span>
                <span class="sub">
                  <h3>
                    <span>{{ item.name }}</span>
                  </h3>
                  <p>
                    <span>{{ item.synopsis }}</span>
                  </p>
                </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </div>
      <!-- 分页按钮 -->
      <van-pagination
        v-model="currentPage"
        :page-count="allPage"
        :total-items="total"
        mode="simple"
        @change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { materialsClass, materials } from '../../api/materials'
import Banner from '@/components/banner.vue'

export default {
  name: 'MaterialsIndex',
  components: {
    Banner
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/2494425522.jpg',
        cnTitle: '材料体系',
        cnTitleColor: '#018835',
        enTitle: 'MATERIAL SYSTEM',
        enTitleColor: '#fff'
      },

      did: null,
      mainClassId: null,
      assistClassId: null,
      classesBase: [
        {
          id: '',
          materialsClassName: '全部'
        }
      ],
      materialsClassList: [],

      materialsList: [],

      total: 0,
      pages: 1,
      limit: 3
    }
  },

  computed: {
    mainMetailClasses() {
      return this.classesBase.concat(
        this.materialsClassList.filter(item => item.did === 1)
      )
    },
    assitantMetailClasses() {
      return this.classesBase.concat(
        this.materialsClassList.filter(item => item.did === 2)
      )
    }
  },

  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      async handler(newV) {
        await this.getClass()
        if (Object.keys(newV).length < 0) {
          this.getData()
        } else {
          const { index, id } = newV
          this.did = Number(index) + 1
          this.did === 1 ? (this.mainClassId = id) : (this.assistClassId = id)
          await this.getData()
        }
      }
    }
  },

  methods: {
    handleCurrentChange(e) {
      this.pages = e
      this.getData()
      document.documentElement.scrollTop = 0
    },
    currentChange(e) {
      this.pages = e
      this.getData()
    },

    mainSelect(id) {
      this.did = 1
      this.assistClassId = null
      this.mainClassId = id
      this.getData()
    },

    assistSelect(id) {
      this.did = 2
      this.mainClassId = null
      this.assistClassId = id
      this.getData()
    },

    async getClass() {
      try {
        const classData = await materialsClass()
        this.materialsClassList = classData.data
      } catch (error) {
        this.$message.error(error)
      }
    },
    async getData() {
      // 判断主材
      if (this.did === 1) {
        // 判断没有主分类
        if (!this.mainClassId) {
          this.mainClassId = ''
        }
        this.assistClassId = null
      } else if (this.did === 2) {
        if (!this.assistClassId) {
          this.assistClassId = ''
        }
        this.mainClassId = null
      } else {
        this.did = 1
        this.mainClassId = ''
      }

      try {
        const params = {
          materialsClassId:
            this.did === 1 ? this.mainClassId : this.assistClassId,
          did: this.did || '',
          limit: this.limit,
          pages: this.pages
        }

        const infoData = await materials(params)
        const list = infoData.data.records
        this.materialsList = list
        this.total = infoData.data.total
      } catch (error) {
        this.$message.error(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.active {
  color: #fff;
  border-radius: 5px;
  background-color: #018835;
}
.Materials {
  width: 100%;

  .MaterialsBox {
    width: 100%;
    margin: 40px auto 0;
    .classBtnBox {
      width: 90%;
      margin: 0 auto;
      padding: 10px 0;
      border: 1px #e5e5e5 solid;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #f9f9f9;
      .left {
        min-width: 70px;
        font-size: 12px;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      ::-webkit-scrollbar {
        display: none;
      }
      .a {
        display: flex;
        overflow-x: scroll;
        .right {
          display: flex;
          align-items: center;
          margin: 0 10px;
          white-space: nowrap;
        }
      }
    }
  }
  .box {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px auto;
    .big {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      border: 1px solid #eee;
      .left {
        height: 100%;
        width: 45vw;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        width: 55vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        .sup {
          padding: 10px;
          height: 70px;
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .sub {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 25px;
          h3 {
            font-size: 24px;
            color: #131313;
            font-weight: bold;
            margin-bottom: 30px;
          }
          p {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .big {
        display: flex;
        flex-direction: column;
        .left {
          height: 100%;
          width: 100%;
        }
        .right {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .van-pagination {
    width: 90%;
    margin: 0 auto 50px;
  }
  ::v-deep .van-pagination {
    .van-pagination__item {
      color: #323232;
    }
    .van-pagination__item:active {
      background-color: #018835;
    }
  }
}
</style>
<style lang="scss">
.box {
  img {
    width: 100%;
  }
}
</style>
