<template>
  <div v-if="picList.length>0" class="photo">
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">COMPANY ALBUM</span>
      <span class="Title">公司相册</span>
    </div>

    <swiper
      :options="swiperOptions"
      class="swiper"
    >
      <template v-for="(item, index) in picList">
        <swiper-slide :key="index">
          <router-link :to="{ name: 'PhotoDetail', query: { id: item.id } }">
            <div class="img">
              <img
                :src="item.cover"
                alt=""
              >
              <span>{{ item.name }}</span>
            </div>
          </router-link>
        </swiper-slide>
      </template>
    </swiper>

    <!-- 底部按钮 -->
    <router-link
      tag="div"
      to="/photo"
      class="btn"
    >
      <span>查看更多</span>
    </router-link>
  </div>
</template>

<script>
import { photo } from '@/api/home'
export default {
  data() {
    return {
      id: '',
      picList: [],
      swiperOptions: {
        speed: 5000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        observer: true,
        observeSlideChildren: true,
        slidesPerView: 2,
        watchOverflow: true,
        loop: true
      }
    }
  },
  created() {
    this.photo()
  },
  methods: {
    async photo() {
      try {
        const params = {
          id: ''
        }

        const res = await photo(params)
        this.picList = res.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/fontButton.scss";
.photo {
  background-color: rgb(255, 255, 255);

  .Chtitle {
    font-size: 23px;
  }
}
.swiper-container {
  width: 100%;
  margin-top: 25px;
  ::v-deep .swiper-wrapper {
    transition-timing-function: cubic-bezier(1, 1, 1, 1) !important;
    .swiper-slide {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img {
        position: relative;
        width: 150px;
        height: 150px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        span {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3vw 0;
        }
      }
    }
  }
}
.el-carousel {
  margin-top: 50px;
}
.el-carousel__item {
  background-color: #000000;
}
.btn {
  margin-top: 50px;
  background-color: #eee;
  width: 30vw;
  height: 10vw;
  margin:20px auto 20px;
  border: 1px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
