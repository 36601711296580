<template>
  <div class="top">
    <h2>安雅园（广东）房屋科技有限公司</h2>
    <label for="burger" class="burger">
      <input id="burger" type="checkbox" @click="show = !show">
      <span />
      <span />
      <span />
    </label>
    <transition name="active">
      <div v-if="show" class="collapse">
        <van-collapse v-model="activeName" accordion>
          <template v-for="item in titleList">
            <van-collapse-item :key="item.id">
              <template #title>
                <router-link :to="item.path">
                  <span @click="closeShow()">{{ item.title }}</span>
                </router-link>
              </template>
              <template v-for="(children, index) in item.children">
                <router-link
                  :key="children.subid"
                  :to="{
                    path: children.path ? children.path : item.path,
                    query: {
                      index: children.did ? children.did : index,
                      id: children.subid,
                    },
                  }"
                >
                  <span class="txt" @click="closeShow()">{{
                    children.title
                  }}</span>
                </router-link>
              </template>
            </van-collapse-item>
          </template>
        </van-collapse>
      </div>
    </transition>
  </div>
</template>

<script>
import { vrClass } from '@/api/home'
import { materialsClass } from '@/api/materials'
import { videoClass } from '@/api/video'
import { newsClass } from '@/api/news'
export default {
  name: 'TopTitle',

  data() {
    return {
      activeName: '',
      show: false,
      vrList: [],
      materList: [],
      videoList: [],
      newsList: []
    }
  },

  computed: {
    titleList() {
      return [
        {
          title: '首页',
          path: '/home'
        },
        {
          title: '关于我们',
          path: '/about',
          children: [
            {
              subid: 1,
              title: '公司介绍'
            },
            {
              subid: 2,
              title: '企业文化'
            },
            {
              subid: 3,
              title: '精英团队'
            },
            {
              subid: 4,
              title: '生产基地'
            },
            {
              subid: 5,
              title: '发展进程'
            },
            {
              subid: 6,
              title: '合作伙伴'
            },
            {
              subid: 7,
              title: '荣誉资质'
            },
            {
              subid: 8,
              title: '公司相册'
            }
          ]
        },

        {
          id: 2,
          title: '业务中心',
          path: '/caseCenter',
          children: [
            {
              did: 0,
              // subid: 1,
              title: '产品展示'
            },
            {
              did: 1,
              // subid: 2,
              title: 'Vertex BD设计'
            },
            {
              did: 2,
              // subid: 3,
              title: '轻钢龙骨生产'
            },
            {
              did: 3,
              // subid: 4,
              title: '建筑材料供应'
            }
          ]
        },

        {
          title: '星标装配',
          path: '/decoration',
          children: [
            {
              seq: true,
              title: '星级装修'
            },
            {
              seq: true,
              title: '宅配模式'
            }
          ]
        },

        {
          title: 'vr全景',
          path: '/vr',
          children: this.vrList
        },
        {
          title: '材料体系',
          path: '/Materials',
          children: this.materList
        },
        {
          title: '视频中心',
          path: '/video',
          children: this.videoList
        },
        {
          title: '科普资讯',
          path: '/news',
          children: this.newsList
        },
        {
          title: '合作赋能',
          path: '/cooperation',
          seq: true,
          children: [
            {
              seq: true,
              title: '合作优势'
            },
            {
              seq: true,
              title: '保障赋能'
            },
            {
              seq: true,
              title: '合作模式'
            }
          ]
        },
        {
          title: '服务项目',
          path: '/serve',
          children: [
            {
              subid: 1,
              title: '下载中心'
            },
            {
              subid: 2,
              title: '常见问题'
            }
          ]
        },
        {
          title: '联系我们',
          path: '/contactUs',
          children: [
            {
              subid: 1,
              title: '在线留言',
              index: 0
            },
            {
              subid: 2,
              title: '加入我们',
              path: '/join'
            }
          ]
        }
      ]
    }
  },
  async created() {
    await this.getClass()
    const id = window.sessionStorage.getItem('onTitleId')
    if (id) {
      this.onTitleId = id
    } else {
      this.onTitleId = 0
    }
  },

  methods: {
    async getClass() {
      try {
        const { data: vrData } = await vrClass()
        this.vrList = vrData.map(item => ({
          subid: item.id,
          title: item.vrClassName
        }))
        const { data: materData } = await materialsClass()
        this.materList = materData.map(item => ({
          subid: item.id,
          title: item.materialsClassName,
          did: item.did - 1
        }))
        const { data: videoData } = await videoClass()
        this.videoList = videoData.map(item => ({
          subid: item.id,
          title: item.videoClassName
        }))
        console.log(this.videoList)
        const { data: newsData } = await newsClass()
        this.newsList = newsData.map(item => ({
          subid: item.id,
          title: item.newsClassName
        }))
      } catch (error) {
        console.error(error)
      }
    },
    closeShow() {
      !this.show
      const burger = document.getElementById('burger')
      burger.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  background-color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  height: 70px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 17px;
  }
  .burger {
    position: relative;
    width: 40px;
    height: 30px;
    background: transparent;
  }

  .burger input {
    display: none;
  }

  .burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }

  .burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }

  .burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
  }

  .burger input:checked ~ span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
  }

  .burger input:checked ~ span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
  }

  .burger input:checked ~ span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 5px;
  }

  .collapse {
    position: absolute;
    width: 300px;
    height: 100vh;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.8);
    top: 70px;
    right: 0;
    z-index: 1;
    span {
      color: #fff;
      font-size: 16px;
    }
    ::v-deep .van-collapse-item__content {
      padding-left: 50px;
      background-color: rgba(255, 255, 255, 0);
      color: #fff;
      font-size: 15px;
      padding: 0;
    }
    ::v-deep .van-cell {
      background-color: rgba(0, 0, 0, 0);
    }
    ::v-deep .van-cell__right-icon {
      color: #fff;
    }
    .txt {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 6px 0 0 35px;
    }
  }
}

.active-enter-active,
.active-leave-active {
  transition: all 0.5s;
}
.active-enter,
.active-leave-to {
  transform: translateX(300px);
}
</style>
