<template>
  <div>
    <div class="bottom">
      <div>
        <ul class="top">
          <span>联系我们</span>
          <li>
            <p>手机：{{ contactDetail.phone }}</p>
          </li>
          <li>
            <p>电话：{{ contactDetail.telephone }}</p>
          </li>
          <li>
            <p>邮箱：{{ contactDetail.companyEmail }}</p>
          </li>
          <li>
            <p>地址：{{ contactDetail.site }}</p>
          </li>
        </ul>
      </div>
      <div class="sub">
        <template v-for="(item, index) in picList">
          <div :key="index" class="box">
            <div class="img">
              <img :src="item.url">
            </div>
            <span>扫码关注</span>
          </div>
        </template>
      </div>
    </div>
    <van-tabbar
      v-model="active"
      class="tabbar"
      active-color="#fff"
      inactive-color="#fff"
      route
    >
      <van-tabbar-item icon="home-o" @click="go">主页</van-tabbar-item>
      <van-tabbar-item
        icon="service-o"
        @click="leave"
      >在线资讯</van-tabbar-item>
      <van-tabbar-item icon="phone-o" @click="callMe">联系我们</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { contactInfo } from '@/api/about'
export default {
  name: 'BottomTitle',

  data() {
    return {
      show: false,
      contactDetail: {},
      active: 0
    }
  },
  computed: {
    picList() {
      const { url } = this.contactDetail
      return url && url.split(';').map(item => ({ url: item }))
    }
  },
  async created() {
    await this.contactInfo()
  },
  methods: {
    call() {
      this.show = true
    },

    go() {
      this.$router.push({ path: '/' })
    },
    leave() {
      this.$router.push({ path: '/contactUs', query: { index: 0 }})
    },
    callMe() {
      window.location.href = `tel://${this.contactDetail.phone}`
    },

    async contactInfo() {
      try {
        const params = {
          id: this.id
        }

        const res = await contactInfo(params)
        this.contactDetail = res.data[0]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  background-color: #2f3239;
  widows: 100%;
  padding: 10px 10px 50px;
  .top {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    span {
      font-size: 17px;
      margin: 20px;
    }
    p {
      font-size: 14px;
      margin: 6px;
    }
  }
  .sub {
    width: 260px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    .box {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .img {
        width: 110px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 10%;
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }
      span {
        color: #fff;
      }
    }
  }
}

.tabbar {
  background-color: #018835;
}
</style>
