<template>
  <!-- 联系我们 -->
  <div class="main">
    <div class="content">
      <!-- 标题 -->
      <div class="Box">
        <span class="Title">JOIN US</span>
        <span class="Chtitle">招聘信息</span>
      </div>
      <div class="box">
        <p>{{ joinList.name }}</p>
        <div class="require">
          <div class="left"><img
            :src="joinList.imgUrl"
            alt=""
          ></div>
          <div class="right">
            <span
              class="top"
              v-html="joinList.details"
            />
            <router-link :to="{ path: '/Joinin' }">
              <span class="button">了解详情</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { join } from '@/api/about'

export default {
  data() {
    return {
      joinList: {}
    }
  },
  async created() {
    await this.join()
  },
  methods: {
    async join() {
      try {
        const params = {
          id: this.id
        }

        const res = await join(params)
        this.joinList = res.data[0]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/fontButton.scss";
.main {
  width: 100%;
  .content {
    width: 100%;
    .Chtitle {
      font-size: 30px;
      color: #000;
      font-weight: bold;
    }
    .box {
      width: 100%;

      p {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        padding: 10px;
        margin-bottom: 10px;
      }
      .require {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .left {
          background-color: #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right {
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .top {
            overflow: hidden;
          }
          .button {
          margin-top: 20px;
            width: 114px;
            line-height: 40px;
            text-align: center;
            display: block;
            color: #fff;
            background: #018835;
            border: #eee 1px solid;
            border-radius: 5px;
            font-size: 15px;
            transition: all 0.3s;

          }
        }
      }
    }
  }
}
</style>
