import Vue from 'vue'
Vue.use(VueRouter)
import VueRouter from 'vue-router'
import Home from '@/views/home'
import About from '@/views/about'
import CaseCenter from '@/views/caseCenter'
import CaseContent from '@/views/CaseContent.vue'
import Decoration from '@/views/decoration'
import Vr from '@/views/vr'
import Materials from '@/views/materials'
import MaterialsContent from '@/views/MaterialsContent.vue'
import Video from '@/views/video'
import VideoContent from '@/views/VideoContent.vue'
import News from '@/views/news'
import NewsContent from '@/views/NewsContent.vue'
import Cooperation from '@/views/cooperation'
import Serve from '@/views/serve'
import ContactUs from '@/views/contactUs'
import PartnerContent from '@/views/PartnerContent.vue'
import Photo from '@/views/photo'
import PhotoDetail from '@/views/PhotoDetail.vue'
import Join from '@/views/join'
import Joinin from '@/views/Joinin.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '安雅园'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/caseCenter',
    name: 'CaseCenter',
    component: CaseCenter,
    meta: {
      title: '业务中心'
    }
  },
  {
    path: '/caseContent',
    name: 'CaseContent',
    component: CaseContent,
    meta: {
      title: '业务中心'
    }
  },
  {
    path: '/decoration',
    name: 'Decoration',
    component: Decoration,
    meta: {
      title: '星标装配'
    }
  },
  {
    path: '/vr',
    name: 'Vr',
    component: Vr,
    meta: {
      title: 'vr全景'
    }
  },
  {
    path: '/materials',
    name: 'Materials',
    component: Materials,
    meta: {
      title: '材料体系'
    }
  },
  {
    path: '/materialsContent',
    name: 'MaterialsContent',
    component: MaterialsContent,
    meta: {
      title: '材料体系'
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
    meta: {
      title: '视频中心'
    }
  },
  {
    path: '/videoContent',
    name: 'VideoContent',
    component: VideoContent,
    meta: {
      title: '视频中心'
    }
  },
  {
    path: '/photo',
    name: 'Photo',
    component: Photo,
    meta: {
      title: '公司相册'
    }
  },
  {
    path: '/photoDetail',
    name: 'PhotoDetail',
    component: PhotoDetail,
    meta: {
      title: '公司相册'
    }
  },
  {
    path: '/partnerContent',
    name: 'PartnerContent',
    component: PartnerContent,
    meta: {
      title: '合作伙伴'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: '科普咨询'
    }
  },
  {
    path: '/newsContent',
    name: 'NewsContent',
    component: NewsContent,
    meta: {
      title: '科普咨询'
    }
  },
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: Cooperation,
    meta: {
      title: '合作赋能'
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/joinin',
    name: 'Joinin',
    component: Joinin,
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/serve',
    name: 'Serve',
    component: Serve,
    meta: {
      title: '服务项目'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  window.scroll(0, 0)
  next()
})

export default router
