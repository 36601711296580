<template>

  <!-- 生产基地 -->
  <div
    class="base"
  >
    <div class="headline">
      <h2>PRODUCTION BASE</h2>
      <h3>生产基地</h3>
    </div>
    <div class="txt">
      {{ aboutList.base }}
    </div>

    <div class="content">
      <template v-for="item in aboutList.img">
        <div
          :key="item.id"
          class="baseimg"
        >
          <img
            :src="item.url"
            class="img"
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { aboutUs } from '@/api/about'
export default {

  data() {
    return {
      aboutList: {}
    }
  },
  async created() {
    await this.getAboutData()
  },
  methods: {
    async getAboutData() {
      const res = await aboutUs()
      this.aboutList = res.data[0]
      this.aboutList.img =
        res.data[0].img &&
        res.data[0].img.split(';').map(item => ({ url: item }))
      console.log(this.aboutList)
    }
  }

}
</script>
<style lang="scss" scoped>

.base {
width:100%;
    .txt {
font-size:15px;
width: 90%;
margin: 0 auto;
line-height: 2;
    }
    .content {
      margin: 0 auto;
   width: 90%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      overflow: hidden;
      .baseimg {
        height: 100%;
        margin-top: 2vw;
        width: 30%;
        .img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>
