<template>
  <div class="case">
    <div class="Box">
      <span class="Subtitle">CLASSIC CASES</span>
      <span class="Title">经典案例</span>
    </div>
    <div class="classBtnBox">

      <template v-for="(item, index) in classBtnList">
        <div :key="item.id"
             class="b"
             :class="{ active: index == btnindex }"
             @click="onClassBtn(item.id, index)">
          <span class="top">
            {{ item.caseClassName }}
          </span>
        </div>
      </template>
      <router-link to="/caseCenter"
                   class="c">
        <span>更多案例+</span>
      </router-link>
    </div>
    <div class="caseContent">
      <template v-for="item in caseList.slice(0, 5)">
      <div 
           :key="item.id"
           class="caseContentSonBox"
           @click="go(item)"
        >
          <!-- <router-link :to="{ name: 'CaseContent', query: { id: item.id } }"> -->
        <img :src="item.imgUrl">
        <div class="hideBox">
          <span>{{ item.caseTitle }}</span>
        </div>
          <!-- </router-link> -->
      </div>
      </template>
    </div>

  </div>
</template>

<script>
import { caseInfo, caseClass } from '@/api/case'
export default {
  data () {
    return {
      classBtnList: [],
      hideBoxList: [],
      caseList: [],
      classId: '',
      id: '',
      btnindex: 0

    }
  },
  async created () {
    await this.getData()
    await this.getClass()
  },
  methods: {
    go (item) {
      this.$router.push({ name: 'CaseContent', query: { id: item.id } })
    },
    onClassBtn (id, index) {
      this.btnindex = index
      this.pages = ''
      this.classId = id
      this.getData()
      console.log(this.classId)
    },
    async getClass () {
      try {
        const classData = await caseClass()
        this.classBtnList = classData.data
      } catch (error) {
        console.error(error)
      }
    },
    async getData () {
      try {
        const params = {
          caseClassId: this.classId,
        }
        const infoData = await caseInfo(params)
        this.caseList = infoData.data.records
        this.total = infoData.data.total
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.case {
  width: 100vw;
  .classBtnBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    .b {
      width: 24%;
      height: 10vw;
      background-color: #eee;
      margin: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4vw;
    }
    .c {
      width: 24%;
      height: 10vw;
      background-color: #eee;
      margin: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4vw;
    }
    @media only screen and (max-width: 600px) {
      .b {
        width: 30%;
      }
      .c {
        width: 91%;
        height: 10vw;
        background-color: #eee;
        margin: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .caseContent {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 20px;
    justify-content: space-between;

    .caseContentSonBox {
      margin-top: 10px;
      width: 49%;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .hideBox {
        position: absolute;
        bottom: 0;
        color: #fff;
        width: 100%;
        font-size: vw;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .caseContentSonBox:first-child {
      width: 100%;
    }
  }
}
.active {
  transition: all 0.5s;
  background-color: #018835 !important;
  color: #fff;
}
</style>
