// 关于我们接口
import request from '@/utils/request'

// 公司简介
export function companyIntro(params) {
  return request({
    url: `/api/companyIntro`,
    method: 'get',
    params
  })
}

// 联系我们
export function contactInfo(params) {
  return request({
    url: `/api/contactInfo`,
    method: 'get',
    params
  })
}
// 关于
export function aboutUs(params) {
  return request({
    url: `/api/aboutus`,
    method: 'get',
    params
  })
}
// 战略伙伴
export function partner(params) {
  return request({
    url: `/api/partner`,
    method: 'get',
    params
  })
}
export function getpartner(params) {
  return request({
    url: `/api/partner/${params.id}`,
    method: 'get'
  })
}
// 荣誉资质
export function glory(params) {
  return request({
    url: `/api/glory`,
    method: 'get',
    params
  })
}
// 给我们留言
export function leave(params) {
  return request({
    url: `/api/leave`,
    method: 'post',
    data: params
  })
}

// 留言分类
export function leaveClass(params) {
  return request({
    url: `/api/leaveClass`,
    method: 'get',
    params
  })
}
// 加入我们
export function join(params) {
  return request({
    url: `/api/join`,
    method: 'get',
    params
  })
}
