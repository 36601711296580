<template>
  <div>
    <component :is="currentTabComponent" />
  </div>
</template>
<script>
import caseShow from '@/views/caseCenter/Case'
import vertexBD from '@/views/caseCenter/VertexBD'
import product from '@/views/caseCenter/Product'
import provision from '@/views/caseCenter/Provision'

export default {
  components: {
    caseShow,
    product,
    provision,
    vertexBD
  },
  data() {
    return {
      currentTabComponent: caseShow,
      classList: [
        {
          name: '产品展示',
          component: caseShow
        },
        {
          name: 'Vertex BD设计',
          component: vertexBD
        },
        {
          name: '轻钢龙骨生产',
          component: product
        },
        {
          name: '建筑材料供应',
          component: provision
        }
      ]
    }
  },
  watch: {
    '$route.query': {
      // immediate: true,
      deep: true,
      handler: function(newV) {
        console.log(newV)
        this.changeTab(newV.index)
      }
    }
  },
  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component
    }
  }
}
</script>

<style lang="scss" scoped></style>
