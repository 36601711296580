<template>
  <div class="about">
    <div class="aboutBox">
      <span>安雅园（广东）房屋科技有限公司</span>
      <p v-html="infoData.content" />
      <router-link tag="div" to="/about" class="btn">了解更多 +</router-link>
    </div>
    <div class="vessel">
      <div class="a" />
      <div class="b" />
      <div class="demo">
        <div class="c">
          <video-player
            ref="videoPlayer"
            class="vjs-custom-skin player"
            :playsinline="true"
            :options="playerOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { companyIntro } from "@/api/about";

export default {
  data() {
    return {
      infoData: {},
      playerOptions: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await companyIntro();
      this.infoData = res.data[0];
      this.playerOptions = {
        // 播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        // 如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "9:4",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            // 类型
            type: "video/mp4",
            // url地址
            src: this.infoData.url,
          },
        ],
        // 你的封面地址
        poster: "",
        // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          // 全屏按钮
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  padding: 40px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .aboutBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: rgb(0, 0, 0);
    span {
      display: block;
      font-family: Arial, "微软雅黑", "宋体";
      color: #3f3f3f;
      font-size: 24px;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      color: #6a6a6a;
      font-weight: 300;
    }
    .btn {
      margin-top: 20px;
      text-align: center;
      line-height: 25px;
      width: 114px;
      height: 30px;
      background-color: white;
      transition: all 0.2s;
      color: #7d7d7d;
      font-size: 14px;
      border: solid 2px #7d7d7d;
    }
  }
  .vessel {
    // width: 100%;
    height: 45vw;
    position: relative;
    .a {
      position: absolute;
      right: 1vw;
      top: 5vw;
      height: 40vw;
      width: 90vw;
      background-color: #e6e6e6;
    }
    .b {
      position: absolute;
      right: 2vw;
      top: 4vw;
      height: 40vw;
      width: 90vw;
      background-color: #f5f5f5;
    }
    .demo {
      position: absolute;
      right: 3vw;
      top: 3vw;
      height: 40vw;
      width: 90vw;
      background-color: #fff;
      .c {
        width: 100%;
      }
    }
  }
}
</style>
