<template>
  <!-- 关于我们 -->
  <div class="about">
    <div class="bannerBox">
      <!-- 文字 -->
      <div class="txt">
        <h2>ABOUT US</h2>
        <h3>关于我们</h3>
      </div>

    </div>
    <profile id="about0" />
    <culture id="about1" />
    <team id="about2" />
    <camp id="about3" />
    <history id="about4" />
    <partner id="about5" />
    <honor id="about6" />
    <Photo id="about7" />
  </div>
</template>

<script>

import profile from './components/profile'
import culture from './components/culture'
import team from './components/team'
import camp from './components/camp'
import history from './components/history'
import partner from './components/partner'
import honor from './components/honor'
import Photo from '../home/components/Photo.vue'

export default {
  name: 'About',
  components: {
    profile,
    culture,
    team,
    camp,
    history,
    partner,
    honor,
    Photo
  },

  data() {
    return {

    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: async function(newV) {
        this.$router.replace({ path: '/about' })
        await this.$nextTick()

        const el = document.querySelector(`#about${newV.index}`)

        window.scrollTo({
          top: el.offsetTop - 80,
          behavior: 'smooth'
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/style/index.scss";
.about {
  .bannerBox {
    margin-top: 70px;
    width: 100%;
    height: 40vw;
    position: relative;
    background: url("~@/assets/banner/maxTopBanner.jpg") no-repeat center ;
    background-size: cover;

    .txt {
      position: absolute;
      top: 5vw;
      right: 5vw;
      h2 {
        font-size: 8vw;
        color: #7a490d;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #000;
        text-align: right;
      }
    }
  }

}
</style>
