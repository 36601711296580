// 对请求方法进行封装
import axios from 'axios'
const service = axios.create({
  baseURL: 'http://www.gdayy.com:8923',
  withCredentials: false,
  timeout: 5000 // 设置超时时间
})

// 请求头参数 目前未设置
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 返回拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      // console.log(res.data);
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.dir('err', error) // for debug
    return Promise.reject(error)
  }
)
export default service
