<template>
  <!-- 正文 -->
  <div class="serviceBox">
    <template v-for="(item, index) in downloadList">
      <div
        :key="item.id"
        class="download"
      >
        <span>{{ item.name }}</span>

        <p
          @click="dowmessage(index)"
        >点击下载</p>

      </div>
    </template>
  </div>
</template>

<script>
import { download } from '@/api/serve'

export default {
  data() {
    return {
      downloadList: []
    }
  },
  created() {
    this.download()
  },
  methods: {
    async download() {
      const res = await download()
      this.downloadList = res.data
      console.log(this.downloadList)
    },
    async dowmessage(index) {
      const currentFile = this.downloadList[index]
      const { file, name } = currentFile
      try {
        this.$dowmessage(file, name)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceBox {
  width: 100%;
  .download {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #eee dashed;
    margin: 10px ;
    span {
      width: 70%;
      padding: 10px;
      font-size: 15px;
         overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
    }
  }
}
</style>
