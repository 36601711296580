<template>
  <div class="why">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">为什么选择安雅园</span>
      <span class="Chtitle">值得信赖的装配式建筑综合服务商</span>
    </div>
    <!-- 内容 -->
    <div>
      <ul class="content">
        <li class=" matter">
          <div>
            <div>
              <img
                :src="img[0].imgUrl"
                alt=""
              >
            </div>
            <div class="txt">
              <h3>先进的设备</h3>
              <p>
                采用FrameMac，大禾众邦，鑫宏华三大设备系列，其中配备F1-C89十四台，F1-C140一台，MF-300一台。
              </p>
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div class="txt">
              <h3>一站式采购</h3>
              <p>
                六大生产基地总仓储面积2.1万方，常备库存超千万，轻钢龙骨和全套辅材轻松一站式采购，集采更优惠、省时更省心。
              </p>
            </div>
            <div>
              <img
                :src="img[1].imgUrl"
                alt=""
              >
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div>
              <img
                :src="img[2].imgUrl"
                alt=""
              >
            </div>
            <div class="txt">
              <h3>一对一方案定制</h3>
              <p>
                方案组为客户提供一对一的平面方案，业务范围涵盖了高档别墅、酒店会所、民宿建筑、生态厕所、景区构筑物等
              </p>
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div class="txt">
              <h3>精准预算</h3>
              <p>
                公司具备完整且成熟的预算工作体系，大量的项目预算经验可以确保预算工作的准确性和高效性，为客户最大化的降低成本，增加利润。
              </p>
            </div>
            <div>
              <img
                :src="img[3].imgUrl"
                alt=""
              >
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div>
              <img
                :src="img[4].imgUrl"
                alt=""
              >
            </div>
            <div class="txt">
              <h3>专业设计团队</h3>
              <p>
                由资深设计总监冯新建打造一支近40人的设计研发团队，细分结构设计，精准预算，建筑方案，研发开发四大组团。
              </p>
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div class="txt">
              <h3>专业售后服务</h3>

              <p>
                安雅园以技术与服务为后盾，产品有任何问题，公司将以设计团队为依托，快速研究处理，解决客户的后顾之忧。
              </p>
            </div>
            <div>
              <img
                :src="img[5].imgUrl"
                alt=""
              >
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div>
              <img
                :src="img[6].imgUrl"
                alt=""
              >
            </div>
            <div class="txt">
              <h3>施工指导</h3>
              <p>
                针对施工过程出现的问题，公司可提供全方位的施工指导，帮助客户解决现场施工问题。
              </p>
            </div>
          </div>
        </li>
        <li class=" matter">
          <div>
            <div class="txt">
              <h3>快速出货</h3>
              <p>
                公司常备库存充足，多条生产线同时生产，订单处理能力强，可快速出货。
              </p>
            </div>
            <div>
              <img
                :src="img[7].imgUrl"
                alt=""
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容2 -->
    <div class="details">
      <h1>以技术和实力为后盾</h1>
      <img
        :src="img[8].imgUrl"
        alt=""
      >
      <div class="txt">
        <h2>50+</h2>
        <p>50人的设计团队</p>
      </div>
      <div class="txt">
        <h2>3000+</h2>
        <p>3000+项目服务案例</p>
      </div>

      <div class="txt">
        <h2>20000+</h2>
        <p>20000方标准化厂房</p>
      </div>
      <div class="txt">
        <h2>1400000+</h2>
        <p>六大生产基地年产量约140万方</p>
      </div>
      <img
        :src="img[9].imgUrl"
        alt=""
      >

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: [
        { imgUrl: require('@/assets/why1.png') },
        { imgUrl: require('@/assets/why2.png') },
        { imgUrl: require('@/assets/why3.png') },
        { imgUrl: require('@/assets/why4.png') },
        { imgUrl: require('@/assets/why5.png') },
        { imgUrl: require('@/assets/why6.png') },
        { imgUrl: require('@/assets/why7.png') },
        { imgUrl: require('@/assets/why8.png') },
        { imgUrl: require('@/assets/why9.jpg') },
        { imgUrl: require('@/assets/why10.jpg') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.why {
  width: 100%;
  .Box {
    width: 100%;
    padding-block: 30px;
    background-color: #018835;
    .Title {
      color: #fff;
      font-size: 26px;
    }
    .Chtitle {
      // font-size: 23px;
      color: #fff;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px;
    .matter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background: #f3f3f3;
      width: 49%;
      margin: 4px 0;
    }

    img {
      width: 100%;
    }

    .txt {
      padding: 10px;
      height: 60vw;
      h3 {

        font-size: 5vw;
        color: #018835;
        margin-bottom: 15px;
        font-weight: bold;
      }
      p {
        font-size: 3vw;
        color: #6c6c6c;
        line-height: 1.8;
        font-weight: 200;

      }
    }
  }
  .details {
    position: relative;
    padding: 4vw;
    background: linear-gradient(40deg, #dcdcdc 0%, #969696 100%);
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: 8vw;
    color: #fff;
    font-weight: bold;
    margin: 20px 0;
  }
  .txt {
    height: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      font-size: 15vw;
      color: #55311e;
      font-weight: bold;
    }
    p {
      font-size: 5vw;
      color: #55311e;
      font-weight: bold;
    }
  }
}
</style>
