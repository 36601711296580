<template>
  <div class="home">
    <!-- 轮播图 -->
    <banner />
    <!-- 关于 -->
    <about />
    <!-- 我们的服务 -->
    <serve />
    <!-- 经典案例 -->
    <caseBox />
    <!-- 为什么选择我们 -->
    <why />
    <!-- 核心产品 -->
    <Core />
    <!-- vr看图 -->
    <vr />
    <!-- 公司相册 -->
    <photo />
    <!-- 服务流程 -->
    <flow />
    <!-- 新闻动态 -->
    <news /></div>
</template>

<script>
import banner from '@/views/home/components/Banner.vue'
import about from '@/views/home/components/About.vue'
import serve from '@/views/home/components/Serve.vue'
import caseBox from '@/views/home/components/Case.vue'
import why from '@/views/home/components/Why.vue'
import Core from './components/Core.vue'
import vr from '@/views/home/components/Vr.vue'
import news from '@/views/home/components/News.vue'
import photo from '@/views/home/components/Photo.vue'
import flow from '@/views/home/components/Flow.vue'
export default {
  components: {
    banner,
    about,
    serve,
    caseBox,
    why,
    Core,
    vr,
    news,
    photo,
    flow

  }
}
</script>

<style lang="scss" scoped>
</style>
