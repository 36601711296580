<template>
  <div class="matter">
    <template v-for="classItem in classList">
      <div :key="classItem.id">
        <div class="big">
          <p>{{ classItem.questionClassName }}</p>
        </div>
        <template v-for="item in dataList">
          <div
            v-show="item.questionClassId == classItem.id"
            :key="item.id"
            class="small"
          >
            <p v-html="item.name" />
            <span v-html="item.answer" />
          </div>
        </template>

        <div
          v-if="limit !== 999"
          class="more"
          @click="more"
        >
          <span>点击加载更多>></span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { questionClass, questionInfo } from '@/api/serve'
export default {
  data() {
    return {
      classList: [],
      dataList: [],
      limit: 9
    }
  },
  watch: {
    limit(oldV, newV) {
      console.log(oldV, newV)
      this.getData()
    }
  },

  async created() {
    await this.getData()
    await this.getClass()
  },
  methods: {
    async getClass() {
      const res = await questionClass()
      this.classList = res.data
    },
    async getData() {
      const params = {
        limit: this.limit
      }
      const res = await questionInfo(params)
      this.dataList = res.data.records
    },
    more() {
      this.limit = 999
    }
  }
}
</script>

<style lang="scss" scoped>
.matter {
  // width: 1200px;
  margin: 20px auto;
  .big {
    width: 100%;
    position: relative;
    padding-left: 12px;
    p {
      padding-left: 10px;
      // width: 1200px;
      background: #ffecd6;
      color: #018835;
      font-size: 20px;
      font-weight: bold;
      line-height: 45px;
    }

    border-left: 5px solid #018835;
  }
  .small {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eee;

    p {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #4d4d4d;
    }
    span {
      font-size: 14px;
      color: rgb(63, 63, 63);
    }
  }
  .more {
    // width: 100%;
    height: 50px;
    margin: 0 30px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    color: #018835;
  }
}
</style>
