<template>
  <!-- 案例展示 -->
  <div class="Video">
    <div class="bannerBox">
      <div class="txt">
        <h2>VIDEO</h2>
        <h3>视频中心</h3>
      </div>
    </div>

    <!-- 正文 -->
    <div class="VideoBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">视频分类</div>
        <div class="a">
          <span
            class="right"
            :class="{ active: mainindex == null }"
            @click="all"
          >全部</span>
          <template v-for="(item, index) in videoClassList">
            <div
              :key="item.id"
              class="right"
              :class="{ active: index == mainindex }"
              @click="onMain(item.id, index)"
            >
              {{ item.videoClassName }}
            </div>
          </template>
        </div>
      </div>
      <!-- 案例 -->
      <div class="detail">
        <template v-for="item in videoList">
          <router-link
            :key="item.id"
            class="caseItem"
            :to="{ path: '/videoContent', query: { id: item.id } }"
          >

            <img
              :src="item.cover"
              class="up"
            >

            <div class="down">
              <span>{{ item.title }}</span>
            </div>
          </router-link>
        </template>
      </div>

      <!-- 分页按钮 -->
      <van-pagination
        v-model="currentPage"
        :page-count="allPage"
        :total-items="total"
        mode="simple"
        @change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { videoClass, video } from '@/api/video'

export default {
  data() {
    return {
      mainindex: null,
      videoClassList: [],
      videoList: [],
      id: '',
      total: 0,
      pages: 1,
      allPage: 0,
      limit: 4,
      classId: ''

    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: async function(newV) {
        this.$router.replace({ name: 'Video' })
        const index = newV.index
        await this.getClass()
        this.$nextTick(() => {
          const id = this.videoClassList[index].id
          this.onMain(id, index)
        })
      }
    }
  },
  async created() {
    // await this.getClass();
    await this.getData()
  },

  methods: {
    handleCurrentChange(e) {
      this.pages = e
      this.getData()
      document.documentElement.scrollTop = 0
    },
    all() {
      this.mainindex = null
      this.classId = ''
      this.getData()
    },
    onMain(id, index) {
      this.mainindex = index
      this.classId = id
      this.getData()
    },
    async getClass() {
      try {
        const classData = await videoClass()
        this.videoClassList = classData.data
      } catch (error) {
        console.error(error)
      }
    },
    async getData() {
      try {
        const params = {
          videoClassId: this.classId,
          limit: this.limit,
          pages: this.pages
        }
        const infoData = await video(params)
        this.videoList = infoData.data.records
        this.total = infoData.data.total
        this.allPage = infoData.data.pages
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  border-radius: 5px;
  background-color: #018835;
  color: #fff;
}
.Video {
  width: 100%;
  .bannerBox {
    margin-top: 70px;
    width: 100%;
    height: 40vw;
    position: relative;
    background: url("~@/assets/banner/Video.jpg") no-repeat center;
    background-size: cover;

    .txt {
      position: absolute;
      top: 5vw;
      right: 5vw;
      h2 {
        font-size: 8vw;
        color: #018835;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #fff;
        text-align: right;
      }
    }
  }
  .VideoBox {
    margin: 40px auto 0;
    .classBtnBox {
      width: 90%;
      height: 50px;
      margin: 0 auto;
      border: 1px #e5e5e5 solid;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        width: 25vw;
        font-size: 2vw;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::-webkit-scrollbar {
        display: none;
      }
      .a {
        background-color: #fff;
        display: flex;
        overflow-x: scroll;
        .right {
          margin: 0 10px 0 0;
          white-space: nowrap;
          padding: 5px;
        }
      }
    }
    .detail {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .caseItem {
        width: 46%;
        margin: 10px;
        box-shadow: 1px 1px 5px #888888;
        .up {
          width: 100%;
          object-fit: cover;
        }
        .down {
          display: flex;
          justify-content: space-between;
          padding: 15px;
          height: 50px;
          span {
            font-size: 15px;
            overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .caseItem {
          width: 100%;
          .down {
            span {
              font-size: 4vw;
              overflow: hidden; //隐藏文字
              white-space: nowrap; //不换行
              text-overflow: ellipsis; //显示 ...
            }
          }
        }
      }
    }
  }
}
.van-pagination {
    width: 90%;
    margin: 0 auto 50px;
  }
  ::v-deep .van-pagination {
    .van-pagination__item {
      color: #323232;
    }
    .van-pagination__item:active {
      background-color: #018835;
    }
  }
</style>
