<template>
  <div class="news">
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">POPULAR SCIENCE INFORMATION </span>
      <span class="Title">科普资讯</span>
    </div>
    <!-- 按钮 -->
    <div class="newsBtn">
      <div
        v-for="item in newsBtn"
        :key="item.id"
        class="newsBtnBox"
        :class="item.id == newsBtnId ? 'btnTextColor' : ''"
        @click="onBtn(item.id)"
      >
        {{ item.newsClassName }}
        <div :class="item.id == newsBtnId ? 'btnColor' : ''" />
      </div>
    </div>
    <!-- 内容 -->
    <div class="newsContent">

      <img
        class="tBox"
        :src="imgUrl"
        alt="
        "
      >

      <template v-for="item in newsList">
        <router-link
          :key="item.id"
          class="bBox"
          :to="{ path: '/newsContent', query: { id: item.id } }"
        >
          <div class="left">
            <span class="date">{{ item.createTime.substring(8,10) }}</span>
            <span class="time">{{ item.createTime.substring(0,7) }}</span>
          </div>
          <div class="right">
            <span class="newsRightBoxTitle">{{ item.newsTitle }}</span>
            <span class="txt">{{ description(item.description) }}</span>
          </div>
        </router-link>
      </template>
    </div>

  </div>
</template>

<script>
import { newsClass, news } from '@/api/news'
export default {
  data() {
    return {
      newsBtn: [],
      newsList: [],
      newsBtnId: ''
    }
  },
  computed: {
    imgUrl() {
      return this.newsList?.[0]?.imgUrl
    }
  },
  async created() {
    await this.getData()
    await this.getBtn()
  },
  methods: {
    description(value) {
      return value.replace(/<[^>]+>/g, '')
    },
    async onBtn(e) {
      this.newsBtnId = e
      this.getData()
    },
    async getBtn() {
      const res = await newsClass()
      this.newsBtn = res.data
    },
    async getData() {
      const params = {
        newsClassId: this.newsBtnId,
        limit: 4
      }
      const newsContent = await news(params)
      this.newsList = newsContent.data.records
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  position: relative;
  .newsBtn {
    display: flex;
    text-align: center;
    line-height: 45px;
    margin: 10px 5vw;
    .newsBtnBox {
      font-size: 1vw;
      flex: 1;
      border-bottom: 1px solid rgb(237, 237, 237);
    }
    .btnTextColor {
      color: #018835;
    }
  }

  .newsContent {
    padding: 20px 5vw;
    display: flex;
    flex-direction: column;
    .tBox {
      width: 100%;
      height: 40vw;
      object-fit: cover;
    }

      .bBox {
        width: 100%;
        border-bottom: 1px solid rgb(237, 237, 237);
        display: flex;
        justify-content: flex-start;
        padding: 10px 0;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
           margin-right: 20px;
          .date {
            color: #018835;
            font-size: 40px;
          }
          .time {
            width: 60px;
            font-size: 15px;
            color: #999999;

          }
        }
        .right {

          display: flex;
          flex-direction: column;
          justify-content: space-around;
          overflow: hidden; //隐藏文字
          .newsRightBoxTitle {

            overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
            color: #333333;
            font-weight: bold;
            margin-bottom: 8px;
            font-size: 16px;
          }
          .txt{
             overflow: hidden; //隐藏文字
            text-overflow: ellipsis; //显示 ...
            white-space: nowrap; //不换行
            color: #999;

            font-size: 10px;
          }
        }

    }
  }

}
</style>
