<template>
  <!-- 详情 -->
  <div class="content">
    <!-- 正文 -->
    <div class="contentBox">
      <div class="top-box">
        <h3>{{ info.name }}</h3>
        <div class="txt">
          <span>更新日期：{{ date }}</span>
        </div>
      </div>
      <div v-if="info.videoUrl" class="viedo-box">
        <VideoPlayer size="16:9" :src="info.videoUrl" />
      </div>
      <div class="box" v-html="info.description" />
    </div>
  </div>
</template>

<script>
import { getMaterials } from '@/api/materials'
import VideoPlayer from '@/components/VideoPlayer.vue'
export default {
  components: {
    VideoPlayer
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      info: {},
      date: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const params = {
          id: this.$route.query.id
        }
        const { data } = await getMaterials(params)
        this.info = data
        this.date = data.time.substring(0, 10)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: #f3f3f3;
  padding: 10px;
  // min-height: 1200px;
  .contentBox {
    background-color: #fff;
    margin: 0 auto;
    // width: 1200px;
    padding: 20px;
    .top-box {
      width: 100%;
      margin: 0 auto;
      h3 {
        font-size: 24px;
        color: #3d3d3d;
        margin-bottom: 10px;
        text-align: center;
      }
      .txt {
        display: flex;
        justify-content: center;
        color: #878787;
        font-size: 14px;
      }
    }
    .viedo-box {
      margin: 10px;
    }
    .box {
      width: 100%;
      overflow: hidden;
      margin: 10px auto;
    }
  }
}
</style>
