<template>
  <div id="about2" class="elite">
    <div class="Box">
      <!-- 标题 -->
      <div class="headline">
        <h2>ELITE TEAM</h2>
        <h3>精英团队</h3>
      </div>
    </div>
    <div class="elite-list">
      <template v-for="(item , index ) in teamList">
        <div :key="index" class="team-list">
          <img class="img" :src="item.img" alt="">
          <div class="team-title">
            {{ item.title }}
          </div>
          <div class="team-detail">
            {{ item.detail }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: '',

  components: {},

  props: {},

  data() {
    return {
      teamList: [
        {
          img: 'https://img.kcgeis.com/1885855472.png',
          title: '01.采购团队',
          detail: '国际化买手，以更优惠的价格买到更优质的原材。'
        },
        {
          img: 'https://img.kcgeis.com/1704220418.png',
          title: '02.研发团队',
          detail: '与时俱进研发智能整装产品，不断提高品牌的竞争力。'
        },
        {
          img: 'https://img.kcgeis.com/9339187554.png',
          title: '03.品控团队',
          detail: '严苛把好每一关，以期给消费者提供行业潮流精品。'
        },
        {
          img: 'https://img.kcgeis.com/8756036771.png',
          title: '04.设计团队',
          detail: '为每一位消费者定制个性化、理想化的装修方案。'
        },
        {
          img: 'https://img.kcgeis.com/9006437146.png',
          title: '05.市场团队',
          detail: '精心筛选合作伙伴，携手共享家装大市场红利。'
        },
        {
          img: 'https://img.kcgeis.com/0131662911.png',
          title: '06.物流团队',
          detail: '高效准时的将商品送至消费者及合作者手中。'
        },
        {
          img: 'https://img.kcgeis.com/5304692508.png',
          title: '07.售后团队',
          detail: '完善的售后机制，为每一次的成交保驾护航。'
        },
        {
          img: 'https://img.kcgeis.com/8496097227.png',
          title: '08.培训团队',
          detail: '全方位体系化运营培训，让合作商快速上手经营。'
        }
      ]
    }
  },

  computed: {},

  methods: {}
}
</script>

<style lang='scss' scoped>
.elite-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .team-list {
        width: 50%;
        padding: 10px;
        .img{
          width: 100%;
        }
        .team-title {
          text-align: center;
          line-height: 45px;
          margin: 10px 0;
          font-size: 24px;
          font-weight: 800;
          color: #383838;
          margin: 10px 0 ;
        }
        .team-detail {
          line-height: 28px;
          font-size: 16px;
          color: #8a8a8a;
          height: 80px;
        }
      }
    }
</style>
