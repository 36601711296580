<template>
  <!-- 新闻 -->
  <div class="new">
    <div class="bannerBox">
      <img :src="img" alt="">
    </div>

    <!-- 正文 -->
    <div class="newBox">
      <div class="rightBox">
        <h2>{{ newsTitle }}</h2>
        <div class="txt">
          <span>更新日期：{{ createTime.substring(0, 10) }}</span>
        </div>
        <div v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import { getNews } from '@/api/news'
import topBanner from '@/assets/banner/news.jpg'
export default {
  data() {
    return {
      id: '',
      // newsList: [],
      description: '',
      newsTitle: '',
      createTime: '',
      img: topBanner
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getNews()
  },

  methods: {
    async getNews() {
      try {
        const { id } = this
        const params = {
          id
        }
        const { data } = await getNews(params)
        const { description, newsTitle, createTime } = data
        this.description = description
        this.newsTitle = newsTitle
        this.createTime = createTime
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new {
  width: 100%;
  .bannerBox {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .rightBox {
    padding: 30px 10px 0;
    h2 {
      font-size: 20px;
      color: #222222;
    }
    .txt {
      display: flex;
      justify-content: center;
      color: #878787;
      font-size: 14px;
      margin: 20px;
    }
  }
}
</style>
