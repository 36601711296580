<template>
  <!-- 详情 -->
  <div class="video">
    <!-- 正文 -->
    <div class="videoBox">
      <div class="top-box ">
        <h3>{{ title }}</h3>
        <div class="txt">
          <span>日期：{{ time.substring(0,10) }}</span>
          <span>来源：{{ source }}</span>
          <span>播放量：{{ play }}</span>
        </div>
      </div>
      <div class="middle-box">
        <div class="demo">
          <VideoPlayer
            size="16:9"
            :src="videoUrl"
            :poster="cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideo, videoInfo } from '../api/video'
import VideoPlayer from '@/components/VideoPlayer.vue'
export default {
  components: {
    VideoPlayer
  },
  data() {
    return {
      id: '',
      play: 0,
      time: '',
      title: '',
      videoUrl: '',
      source: '',
      playerOptions: {}
    }
  },
  async created() {
    this.id = this.$route.query.id
    await this.getData()
    await this.videoInfo()
  },
  methods: {
    async getData() {
      try {
        const { id } = this
        const params = {
          id
        }
        const { data } = await getVideo(params)
        const { play, time, videoUrl, source, title, cover } = data
        this.play = play
        this.time = time
        this.videoUrl = videoUrl
        this.source = source
        this.title = title
        this.cover = cover
      } catch (error) {
        console.log(error)
      }
    },

    async videoInfo() {
      try {
        const { id, play } = this
        const params = {
          id: id,
          play: Number(play) + 1
        }
        await videoInfo(params)
        console.log(111)
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  background-color: #f3f3f3;
  padding: 10px;
  .videoBox {
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    .top-box {
      width: 100%;
      margin: 0 auto;
      h3 {
        font-size: 24px;
        color: #3d3d3d;
        margin-bottom: 10px;
        text-align: center;
      }
      .txt {
        display: flex;
        justify-content: space-around;
        color: #878787;
        font-size: 14px;
      }
    }
    .middle-box {
      background-color: #f3f3f3;
      margin: 20px auto;
      width: 90%;
      // margin-bottom: 70px;
      // padding: 20px 0;
      // overflow: hidden;
    }
  }
}
</style>
