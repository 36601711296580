<template>
  <div class="way">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">合作模式</span>
      <span class="Chtitle">灵活合作经营 共创事业蓝图</span>
    </div>
    <img class="img" src="https://img.kcgeis.com/5712097649.png">
  </div>
</template>

<script>
export default {
  name: 'CWay',

  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.way {
  .img {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
