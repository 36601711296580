<template>
  <!-- 公司介绍 -->
  <div
    class="icd"
  >
    <div class="headline">
      <h2>COMPANY PROFILE</h2>
      <h3>企业介绍</h3>
    </div>
    <div class="txt">
      <div v-html="aboutList.aboutus" />
    </div>

    <img
      class="bottom"
      src="@/assets/1622103056879102.png"
    >

  </div>

</template>

<script>
import { aboutUs } from '@/api/about'
export default {

  data() {
    return {
      aboutList: {}
    }
  },
  async created() {
    await this.getAboutData()
  },
  methods: {
    async getAboutData() {
      const res = await aboutUs()
      this.aboutList = res.data[0]
    }
  }

}
</script>

<style lang="scss" scoped>
.icd {
  width: 100%;
    background: url("~@/assets/jjbj1.jpg") no-repeat center ;
    background-size: cover;

  .txt{
    padding: 3vw;
  }
  .bottom{
    width: 100%;
    padding: 5vw;
     box-sizing: border-box;

  }
}
</style>
