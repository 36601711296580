<template>
  <div class="advantage">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">合作优势</span>
      <span class="Chtitle">对话财富 实现强大叠加效益</span>
    </div>
    <div class="elite-list">
      <template v-for="(item, index ) in teamList">
        <div :key="index" class="team-list">
          <img class="img" :src="item.img">
          <div class="team-title">
            {{ item.title }}
          </div>
          <div class="team-detail">
            {{ item.detail }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CAdvantage',

  components: {},

  props: {},

  data() {
    return {
      teamList: [
        {
          img: 'https://img.kcgeis.com/9811151179.png',
          title: '市场需求大',
          detail: '新房要装修、二手房要装修，消费市场需求大，装修装饰市场极速扩张，机会多。'
        },
        {
          img: 'https://img.kcgeis.com/6523307991.png',
          title: '全屋整装好',
          detail: '颠覆传统装修模式，像拼图一样进行全屋整装，绿色环保、清新自然、极速整装。'
        },
        {
          img: 'https://img.kcgeis.com/7202118584.png',
          title: '品牌实力强',
          detail: '大型现代化产业基地，先进的生产制造设备，供货有保障，售后无忧虑。'
        },
        {
          img: 'https://img.kcgeis.com/6730832431.png',
          title: '产品一站配',
          detail: '新型绿色环保建材，从硬装到软装，打造完整的产业链条，全房选材一站配齐。'
        },
        {
          img: 'https://img.kcgeis.com/9511902442.png',
          title: '投入成本低',
          detail: '和厂家直接合作，扁平化渠道更省成本，投资可大可小，店面可有可无。'
        },
        {
          img: 'https://img.kcgeis.com/0872644770.png',
          title: '创业风险小',
          detail: '无需将资金压在库存上，无需实体店面，创业无压力，接到订单就有赚。'
        },
        {
          img: 'https://img.kcgeis.com/3216409583.png',
          title: '运营模式新',
          detail: '一店顶N店，托管式运营扶持，共享仓储，共享技术，共享团队，创业省心省力。'
        },
        {
          img: 'https://img.kcgeis.com/0398188227.png',
          title: '合作利润足',
          detail: '省去中间商层层盘剥，直接以底价供货，合作商赚取差价利润。'
        }
      ]
    }
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.advantage {
  margin: 0 auto;

  .elite-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .team-list {
      width: 50%;
      padding: 10px;
      .img {
        width: 100%;
      }

      .team-title {
        text-align: center;
        line-height: 45px;
        margin: 10px 0;
        font-size: 24px;
        font-weight: 800;
        color: #383838;
        margin: 10px 0;
      }

      .team-detail {
        line-height: 28px;
        font-size: 16px;
        color: #8a8a8a;
        height: 120px;
      }
    }
  }
}
</style>
