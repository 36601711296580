<template>
  <!-- 联系我们 -->
  <div class="main">
    <div class="map">
      <baidu-map
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        style="height: 100%"
      >
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
        />
        <bm-marker
          :position="{ lng: 113.13816, lat: 22.56931 }"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-label
            content="安雅园（广东）房屋科技有限公司"
            :label-style="{ color: 'red', fontSize: '10px' }"
            :offset="{ width: -55, height: 30 }"
          />
        </bm-marker>
      </baidu-map>
    </div>

    <div class="content">
      <!-- 标题 -->
      <div class="Box">
        <span class="Title">CONTACT US</span>
        <span class="Chtitle">联系我们</span>
      </div>
      <!-- 简介 -->
      <div class="title">
        <span>如果您正在考虑选择一个轻钢材料供应商？那么，最好的厂家就在这里。
        </span>
        <span>在安雅园，我们致力于成为轻钢行业最值得信赖的综合服务商，始终秉承以技术为核心，以品质为保障，以服务为宗旨，为客户提供出最优的解决方案，真正的做到服务客户，尊重客户。</span>
      </div>
      <!-- 联系方式 -->
      <div class="contact-info">
        <template v-for="(item,index) in contactList">
          <div
            :key="index"
            class="contactList"
          >
            <img
              :src="item.img"
              class="item-icon"
            >
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-detail">
              {{ item.detail }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <div id="contact0" class="call">
      <div class="title">
        <h3>在线留言</h3>
      </div>
      <div class="card">
        <span>温馨提示：有任何问题或需求，欢迎您随时咨询我们，也可以填写下面留言板，我们会即时为您服务！</span>
        <van-form ref="formRef" validate-trigger="onSubmit" @submit="onSubmit">
          <van-field
            v-model="form.name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.phone"
            label="电话"
            placeholder="请输入电话"
            :rules="[{ required: true }]"
          />
          <van-field
            readonly
            clickable
            name="picker"
            :value="value"
            label="资讯的内容"
            placeholder="点击选择内容"
            :rules="[{ required: true }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="leavelist"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-field
            v-model="form.content"
            rows="1"
            autosize
            label="留言"
            type="textarea"
            placeholder="请写下其他需求或问题"
            show-word-limit
            :rules="[{ required: true }]"
          />

          <div style="margin: 16px">
            <van-button
              round
              block
              type="primary"
              native-type="submit"
            >提交</van-button>
          </div>
        </van-form>
        <van-dialog v-model="show" @confirm="confirm">
          <h2 style="padding: 20px">我们已收到您的留言</h2>
        </van-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { contactInfo, leave, leaveClass } from '@/api/about'

export default {
  data() {
    return {
      show: false,
      id: '',
      center: '江门市江海区火炬大厦', // 经纬度
      zoom: 18, // 地图展示级别
      contactDetail: [],
      phone: '',
      classList: [],
      contactList: [
        {
          img: 'https://img.kcgeis.com/0026644965.png',
          title: '公司名称',
          detail: ''
        },
        {
          img: 'https://img.kcgeis.com/8237578829.png',
          title: '咨询热线',
          detail: ''
        },
        {
          img: 'https://img.kcgeis.com/3335677513.png',
          title: '网址',
          detail: ''
        },
        {
          img: 'https://img.kcgeis.com/0681666503.png',
          title: '公司地址',
          detail: ''
        }
      ],
      form: {
        name: '',
        phone: '',
        content: '',
        classid: ''
      },
      value: '',
      showPicker: false
    }
  },
  computed: {
    leavelist() {
      return this.classList?.map(value => value.name)
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: async function(newV) {
        this.$router.replace({ name: 'ContactUs' })
        await this.$nextTick()
        const el = document.querySelector(`#contact${newV.index}`)
        window.scrollTo({
          top: el.offsetTop,
          behavior: 'smooth'
        })
      }
    }
  },
  async created() {
    await this.contactInfo()
    this.getClass()
  },
  methods: {
    onConfirm(value) {
      this.value = value
      this.showPicker = false
      this.form.classid = this.classList.filter(
        item => item.name === value
      )[0].id
    },
    onSubmit() {
      this.$refs.formRef.validate().then(async() => {
        try {
          const params = {
            content: this.form.content,
            name: this.form.name,
            phone: this.form.phone,
            leaveClassId: this.form.classid
          }
          await leave(params)
          this.show = true
        } catch (error) {
          console.log(error)
        }
      })
    },
    confirm() {
      location.reload()
      this.$router.go(0)
    },
    async getClass() {
      try {
        const classData = await leaveClass()
        this.classList = classData.data
      } catch (error) {
        console.error(error)
      }
    },
    async contactInfo() {
      try {
        const { data } = await contactInfo()
        this.contactList[0].detail = data[0].content
        this.contactList[1].detail = data[0].phone
        this.contactList[2].detail = 'http://www.gdayy.com'
        this.contactList[3].detail = data[0].site
      } catch (error) {
        this.$message.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/fontButton.scss';
.main {
  width: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 450px;
    .bm-view {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    width: 100%;
    .Chtitle {
      font-size: 30px;
      color: #000;
      font-weight: bold;
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      margin: 50px auto 0;
      span {
        font-size: 14px;
        color: #434343;
      }
    }
    .contact-info {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      .contactList {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

        .item-icon{
          width: 30px;
        }

        .item-title {
          color: #1d2122;
          font-weight: 800;
          font-size: 18px;
          margin: 10px 0 ;
        }
        .item-detail {
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
  .call {
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      padding: 30px;
      h3 {
        font-size: 24px;
        color: #434343;
        margin-bottom: 10px;
      }
    }
    .card {
      background: #fff;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      span {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: #018835;
      }
      .el-form {
        margin-top: 20px;
        width: 900px;
      }
    }
  }
}
</style>
