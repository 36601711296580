// 材料接口
import request from '@/utils/request'

// 材料分类标题
export function materialsClass(params) {
  return request({
    url: `/api/materialsClass`,
    method: 'get',
    params
  })
}

// 材料内容
export function materials(params) {
  return request({
    url: `/api/materialsInfo`,
    method: 'get',
    params
  })
}
// 材料详情
export function getMaterials(params) {
  return request({
    url: `/api/materialsInfo/${params.id}`,
    method: 'get'
  })
}
