<template>
  <!-- 详情 -->
  <div class="main">
    <!-- 正文 -->
    <div class="mainBox">
      <div class="rightBox">
        <h2>{{ caseData.caseTitle }}</h2>
        <div class="top-box">
          <div
            id="swiper"
            class="swiper"
          >
            <div class="swiper-wrapper">
              <template v-for="(item, index) in casePics">
                <div
                  :key="index"
                  class="swiper-slide"
                >
                  <img
                    class="pics"
                    :src="item.url"
                    alt=""
                  >
                </div>
              </template>
            </div>
          </div>
          <div
            id="thumbs"
            class="thumbs"
          >
            <div class="swiper-wrapper">
              <template v-for="(item, index) in casePics">
                <div
                  :key="index"
                  class="swiper-slide"
                >
                  <img
                    class="pics"
                    :src="item.url"
                    alt=""
                  >
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="bottom-box">
          <div class="text">
            <h5>{{ caseData.caseTitle }}</h5>
            <h6>{{ caseData.CreateTime }}</h6>
          </div>
          <ul class="table">
            <li class="box">
              <span class="title">案例风格</span>
              <span class="txt">{{ caseStyleName }}</span>
            </li>
            <li class="box">
              <span class="title">案例户型</span>
              <span class="txt">{{ caseHouseName }}</span>
            </li>
            <li class="box">
              <span class="title">案例层高</span>
              <span class="txt">{{ caseHighName }}</span>
            </li>
            <li class="box">
              <span class="title">案例面积</span>
              <span class="txt">{{ caseAreaName }}</span>
            </li>
            <li class="box">
              <span class="title">承接业务</span>
              <span class="txt ">{{ caseData.business }}</span>
            </li>
            <li class="box">
              <span class="title">工艺用材</span>
              <span class="txt ">{{ caseData.craft }}</span>
            </li>
          </ul>
          <div class="case">
            <h5>案例详情</h5>
            <div class="border">
              <span v-html="details" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCase, caseHigh, caseArea, caseStyle, caseHouse } from '../api/case'
import Swiper from 'swiper'
export default {
  data() {
    return {
      caseData: {},
      caseStyleList: [],
      caseHouseList: [],
      caseHighList: [],
      caseAreaList: []
    }
  },
  computed: {
    caseStyleName() {
      return this.caseStyleList.find(item => item.id === this.caseData.caseStyleId)
        ?.caseStyleName
    },
    caseHouseName() {
      return this.caseHouseList.find(item => item.id === this.caseData.caseHouseId)
        ?.caseHouseName
    },
    caseHighName() {
      return this.caseHighList.find(item => item.id === this.caseData.caseHighId)
        ?.caseHighName
    },
    caseAreaName() {
      return this.caseAreaList.find(item => item.id === this.caseData.caseAreaId)
        ?.caseAreaName
    },
    casePics() {
      return this.caseData.pics?.split(';').map(item => ({ url: item }))
    }
  },
  async created() {
    this.id = this.$route.query.id
    await this.initCase()
    this.initSwiper()
  },

  methods: {
    async initCase() {
      await this.getCase()
      await this.caseHigh()
      await this.caseArea()
      await this.caseStyle()
      await this.caseHouse()
    },
    initSwiper() {
      new Swiper('#swiper', {
        autoplay: true, // 可选选项，自动滑动
        loop: true,
        thumbs: {
          swiper: {
            el: '#thumbs', // 注意此处的设置方式

            slidesPerView: 5,
            watchSlidesVisibility: true
          },
          slideThumbActiveClass: 'active'
          // autoScrollOffset: 1
        }
      })
    },
    async getCase() {
      try {
        const { id } = this
        const params = {
          id
        }
        const { data } = await getCase(params)
        this.caseData = data
      } catch (error) {
        console.log(error)
      }
    },
    async caseHigh() {
      try {
        const caseHighData = await caseHigh()
        this.caseHighList = caseHighData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseHouse() {
      try {
        const caseHouseData = await caseHouse()
        this.caseHouseList = caseHouseData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseArea() {
      try {
        const caseAreaData = await caseArea()
        this.caseAreaList = caseAreaData.data
      } catch (error) {
        console.error(error)
      }
    },
    async caseStyle() {
      try {
        const caseStyleData = await caseStyle()
        this.caseStyleList = caseStyleData.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #f3f3f3;
  padding-top: 20px;
  .rightBox {
    background-color: #fff;
    margin: 0 auto;
    font-size: 3vw;
    // width: 1300px;
    padding: 20px 30px;
    .top-box {
      background-color: #f3f3f3;
      margin: 20px auto 0;
      width: 90%;
      // height: 600px;

      overflow: hidden;

      .swiper {
        // width: 800px;
        // height: 400px;
        overflow: hidden;
        margin: 0 auto 25px;
        .pics {
          width: 100%;
          height: 100%;
        }
      }
      .thumbs {
        .swiper-wrapper {
          height: 20vw;
          .swiper-slide {
            height: 10vw;
            width: 100%;
            overflow: hidden;
            margin: 0 10px;
          }
          .pics {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .active {
            border: 3px solid #018835;
          }
        }
      }
    }
    .bottom-box {
      margin: 20px auto 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .text {
        border: #eaeaea solid 1px;
        height: 100px;
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        h5 {
          font-size: 20px;
          color: #333;
        }
        h6 {
          font-size: 14px;
          color: #666666;
        }
      }
      .table {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .box {
          width: 100%;
          display: flex;
          .title {
            min-height: 50px;
            min-width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f2f2f2;
            color: #000000;
            font-size: 15px;
            border-top: #eaeaea solid 1px;
          }
          .txt {
            padding: 10px;
            width: 100%;
            // height: 50px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: #666666;
            font-size: 14px;
            border: 1px solid #f3f3f3;
          }
        }
      }
      .case {
        margin-top: 20px;
        h5 {
          font-size: 15px;
          border: #eaeaea solid 1px;
          width: 100px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666;
        }
        .border {
          width: 100%;
          border-top: #eaeaea 1px solid;
          padding: 30px 50px 100px;
        }
      }
    }
  }
}
</style>
