<template>
  <!-- 新闻 -->
  <div class="new">
    <div class="bannerBox">
      <div class="txt">
        <h2>INFORMATION</h2>
        <h3>科普资讯</h3>
      </div>
    </div>
    <!-- 正文 -->
    <div class="newBox">
      <!-- 左侧列表按钮 -->
      <div class="leftBtnBox">
        <template v-for="(item, index) in newsClassList">
          <div :key="item.id"
               class="btnBox"
               :class="{ active: index == styleindex }"
               @click="onMain(item.id, index)">
            {{ item.newsClassName }}
          </div>
        </template>
      </div>

      <!-- 右侧信息列表 -->
      <div class="rightBox">
        <router-link v-for="item in newsList"
                     :key="item.id"
                     tag="div"
                     :to="{ path: '/NewsContent', query: { id: item.id } }"
                     class="newsList">
          <div class="img">
            <img :src="item.imgUrl"
                 alt="" />
          </div>
          <div class="txt">
            <p>{{ item.newsTitle }}</p>
            <h3>{{ item.author }}</h3>
            <span>{{ item.createTime }}</span>
          </div>
        </router-link>
        <div class="bottomBtn" />
      </div>
    </div>
  </div>
</template>

<script>
import { newsClass, news } from "@/api/news";

export default {
  data () {
    return {
      id: "",

      newsClassList: [],
      newsList: [],
      classId: "",
      pages: 1,
      total: 0,
      limit: 5,
      styleindex: 0,
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler: async function (newV) {
        this.$router.replace({ name: "News" });
        const index = newV.index;
        await this.getClass();
        this.$nextTick(() => {
          const id = this.newsClassList[index].id;
          this.onMain(id, index);
        });
      },
    },
  },
  async created () {
    // this.getClass();
    await this.getData();
  },
  methods: {
    onMain (id, index) {
      this.styleindex = index;
      this.classId = id;
      this.getData();
    },
    handleCurrentChange (e) {
      this.pages = e;
      this.getData();
      document.documentElement.scrollTop = 0;
    },
    async getClass () {
      try {
        const classData = await newsClass();
        this.newsClassList = classData.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getData () {
      try {
        const params = {
          newsClassId: this.classId,
          limit: this.limit,
          pages: this.pages,
        };
        const infoData = await news(params);
        this.newsList = infoData.data.records;
        this.total = infoData.data.total;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #018835;
  font-weight: 800;
}
.new {
  width: 100%;
  .bannerBox {
    margin-top: 70px;
    width: 100%;
    height: 40vw;
    max-height: 200px;
    position: relative;
    background: url("~@/assets/banner/news.jpg") no-repeat center;
    background-size: cover;

    .txt {
      position: absolute;
      top: 4vw;
      right: 5vw;
      h2 {
        font-size: 8vw;
        color: #018835;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #fff;
        text-align: right;
      }
    }
  }
  .newBox {
    width: 100%;
    .leftBtnBox {
      background-color: #eee;
      display: flex;
      justify-content: space-around;
      padding: 20px;
      .btnBox {
        font-size: 15px;
        background-color: #e7e7e7;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
    .rightBox {
      width: 100%;
      padding: 20px;
      .newsList {
        width: 100%;
        border-bottom: 1px solid #33333320;
        display: flex;
        justify-content: flex-start;
        padding: 10px 0;
        .img {
          width: 40%;
          height: 20vw;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .txt {
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px;
          p {
            font-size: 16px;
            color: #2e2e2e;
            font-weight: bold;
          }
          h3 {
            font-size: 14px;
            color: #7f7f7f;
            overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
          }
          span {
            font-size: 14px;
            color: #7f7f7f;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .newsList {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img {
            width: 100%;
            height: 40vw;
          }
          .txt {
            width: 100%;
            height: 30vw;
          }
        }
      }
    }
  }
}
</style>
