<template>
  <div class="vr">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">VR全景看厂</span>
      <span class="Chtitle">更加真实的了解我们，多视角感受</span>
    </div>

    <van-swipe
      :autoplay="2000"
      indicator-color="white"
    >
      <template v-for="(item, index) in picList">
        <van-swipe-item :key="index">
          <a
            :href="item.url"
            target="_blank"
          >
            <img :src="item.photo">
            <span>{{ item.name }}</span>
          </a>
        </van-swipe-item>
      </template>
    </van-swipe>

  </div>
</template>
<script>
import { vrbanner } from '@/api/case'
export default {
  data() {
    return {
      id: '',
      picList: []
    }
  },
  created() {
    this.vrbanner()
  },
  methods: {
    async vrbanner() {
      try {
        const params = {
          id: ''
        }
        const res = await vrbanner(params)
        this.picList = res.data.records
        console.log(this.picList)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Chtitle{
  font-size: 4vw;
}
.van-swipe{
  width: 90vw;
  margin: 0 auto 50px;

  .van-swipe-item{
    width: 100vw;
    height: 50vw;
    position: relative;
  img{
    border-radius: 25px;
    width: 100%;
    height:  100%;
    object-fit: cover;
  }
  span{
    width: 100%;
    height: 6vw;
    font-size: 3vw;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom:6vw;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}
}
</style>

