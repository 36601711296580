<template>
  <!-- 案例展示 -->
  <div class="photo">
    <div class="bannerBox">
      <div class="txt">
        <h2>COMPANY ALBUM</h2>
        <h3>公司相册</h3>
      </div>
    </div>

    <!-- 正文 -->
    <div class="photoBox">
      <!-- 案例 -->
      <div class="detail">
        <template
          v-for="item in picList"
        >
          <router-link
            :key="item.id"
            class="caseItem"
            :to="{ path: '/PhotoDetail', query: { id: item.id } }"
          >

            <img
              :src="item.cover"
              class="up"
            >
            <div class="down">
              <span>{{ item.name }}</span>
            </div>
          </router-link>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import { photo } from '@/api/home'
export default {
  data() {
    return {
      picList: [],
      id: ''
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    async getData() {
      const res = await photo()
      this.picList = res.data
    }
  }
}
</script>

<style lang="scss" scoped>

.photo {
  width: 100%;
  .bannerBox {
    margin-top: 70px;
    width: 100%;
    height: 40vw;
    position: relative;
    background: url("~@/assets/banner/photo.jpg") no-repeat center ;
    background-size: cover;

    .txt {
      position: absolute;
      top: 5vw;
      right: 5vw;
      h2 {
        font-size: 8vw;
        color: #018835;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #fff;
        text-align: right;
      }
    }
  }
  .photoBox {
    margin: 40px auto 0;

  }
     .detail{
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
.caseItem{
  width: 46%;
  margin: 10px;
  box-shadow: 1px 1px 5px #888888;
  .up{
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  .down{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    height: 20%;
    span{
      font-size: 15px;
       overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
    }

  }

}
@media only screen and (max-width: 600px) {
.caseItem{
  width: 100%;
  .down{
   span{
      font-size: 4vw;
       overflow: hidden; //隐藏文字
            white-space: nowrap; //不换行
            text-overflow: ellipsis; //显示 ...
    }}
}
        }
}
  }

</style>
