// 新闻动态接口
import request from '@/utils/request'

// 新闻分类标题
export function newsClass(params) {
  return request({
    url: `/api/newsClass`,
    method: 'get',
    params
  })
}

// 新闻内容
export function news(params) {
  return request({
    url: `/api/news`,
    method: 'get',
    params
  })
}
// 新闻详情
export function getNews(params) {
  return request({
    url: `/api/news/${params.id}`,
    method: 'get'
  })
}
