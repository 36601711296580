<template>
  <!-- 荣誉资质 -->
  <div
    v-if="gloryList.length>0"
    class="honor"
  >
    <div class="headline">
      <!-- 标题 -->
      <h2>HONOR AND QUALIFICATION</h2>
      <h3>荣誉资质</h3>
    </div>

    <swiper :options="swiperOptions">
      <template v-for="(item, index) in gloryList">
        <swiper-slide :key="index">
          <div class="img">
            <img
              :src="item.imgUrl"
              alt=""
            >
          </div>
          <p>{{ item.gloryName }}</p>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { glory } from '@/api/about'
export default {
  data() {
    return {
      gloryList: [],
      swiperOptions: {
        speed: 5000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        observer: true,
        observeSlideChildren: true,
        watchOverflow: true,
        // slidesPerView: 'auto',
        spaceBetween: 60,
        loop: true
      }
    }
  },
  async created() {
    await this.glory()
  },
  methods: {

    async glory() {
      try {
        const params = {
          id: ''
        }

        const res = await glory(params)
        this.gloryList = res.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.honor {
    background: url("~@/assets/about/bg.jpg");
    // height: 200px;
    width: 100%;
.headline{
  h3{
    color: #fff;
    margin:3vw;
  }
}
       .swiper-container {
      height: 400px;
   width: 100%;
      margin-top: 50px;

      ::v-deep .swiper-wrapper {
        transition-timing-function: cubic-bezier(1, 1, 1, 1) !important;
        .swiper-slide {
          height: 350px;
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .img {
            height: 100%;
            width: auto;

            img {
              height: 100%;
              width: auto;
              object-fit: cover;
            }
          }
          p {
            margin-top: 10px;
            font-size: 20px;
          }
        }
      }

    }
  }
</style>
