<template>
  <div class="safeguard">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">保障赋能</span>
      <span class="Chtitle">保姆式精准扶持 提升竞争力</span>
    </div>
    <div class="elite-list">
      <template v-for="(item , index ) in teamList">
        <div :key="index" class="team-list">
          <img :src="item.img" class="img">
          <div class="team-title">
            {{ item.title }}
          </div>
          <div class="team-detail">
            {{ item.detail }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CSafeguard',

  components: {},

  props: {},

  data() {
    return {
      teamList: [
        {
          img: 'https://img.kcgeis.com/6741849027.png',
          title: '形象输出支持',
          detail: '公司培养有专业的空间设计师，可根据店铺实际情况，专门为经销商提供完整的卖场设计方案。'
        },
        {
          img: 'https://img.kcgeis.com/6738526875.png',
          title: '品牌物料支持',
          detail: '品牌形象用品、宣传用品等-系列经营中所需物料，均由总部统一设计及提供方案。'
        },
        {
          img: 'https://img.kcgeis.com/6668399089.png',
          title: '装修补贴支持',
          detail: '只要经销商专卖店按照公司统标准装修，公司将大力度提供装修补贴。'
        },
        {
          img: 'https://img.kcgeis.com/2409518026.png',
          title: '活动促销支持',
          detail: '开业、节假日促销活动，公司提供整套宣传物料、大量礼品以及优惠折扣和现场策划方案，终端决胜不是问题。'
        },
        {
          img: 'https://img.kcgeis.com/7926997125.png',
          title: '广告宣传支持',
          detail: '做当地广告宣传及品牌宣传活动时，公司大力支持各项广告及费用补贴。'
        },
        {
          img: 'https://img.kcgeis.com/8477966843.jpg',
          title: '门店经营指导',
          detail: '专业的门店经营指导，系统的门店导购培训，你只需招到忠诚的人，公司提供专业的员工培训。'
        },
        {
          img: 'https://img.kcgeis.com/3004199462.png',
          title: '技术培训支持',
          detail: '专业化的品牌培训团队，通过对一课堂、培训手册等形式对安装、导购、销售进行培训，提升人员战斗力。'
        },
        {
          img: 'https://img.kcgeis.com/4215377320.png',
          title: '物流配送支持',
          detail: '与多家物流公司达成长期合作协议，提供精准优质物流支持，公司还建立专门订货系统，按照代理商要求实施物流配送统一化。'
        },
        {
          img: 'https://img.kcgeis.com/8064478272.png',
          title: '年终返利支持',
          detail: '总部给予经销商销售奖励支持，按销售金额百分比返利业绩越高奖励越多。'
        },
        {
          img: 'https://img.kcgeis.com/8925884820.png',
          title: '区域保护支持',
          detail: '严格实行商圈保护，避免同品牌同区域代理商恶性竞争，代理商还享有新开辟市场的代理优先权和续约优先权。'
        }
      ]
    }
  },

  computed: {},

  methods: {}
}
</script>

<style lang='scss' scoped>
.safeguard {
  margin: 0 auto;
  .elite-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .team-list {
      width:50%;
      padding: 5px;
      border: 1px dashed #b8b8b8;

      .img {
        width: 100%;
      }
      .team-title {
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        padding: 10px 0 ;
        color: #383838;
        border-bottom: 1px dashed #b8b8b8;
      }
      .team-detail {
        padding: 15px ;
        line-height: 25px;
        font-size: 15px;
        color: #8a8a8a;
        height: 165px;
      }
    }
  }
}
</style>
