<template>
  <!-- 企业文化 -->
  <div
    class="culture"
  >
    <img
      class="top"
      src="@/assets/1622103056879103.png"
      alt=""
    >
    <div class="headline">
      <h2>ENTERPRISE CULTURE</h2>
      <h3>企业文化</h3>
    </div>
    <div class="txt">
      <span>
        <h4>核心价值观</h4>
        <p>团结、诚信、创新、共赢</p>
      </span>
      <span>
        <h4>企业精神</h4>
        <p>爱岗敬业创佳绩、求真务实谋发展</p>
      </span>
      <span style="width:100%">
        <h4>经营理念</h4>
        <div class="idea">
          <span style="background-color:#f4f4f4">
            <h5>以技术为核心</h5>
            <p>
              作为一家装配式建筑综合服务商，技术永远是核心。用心研发技术和产品，推动技术创新、保持技术优势是安雅园立于不败之地的保证。
            </p>
          </span>
          <span style="background-color:#e3e3e3">
            <h5>以品质为保障</h5>
            <p>
              品质是企业生产和发展的基石；用工匠精神来做设计、搞生产；练好内功，铸好品质，不打价格战，不搞恶性竞争，既是对自身负责，也是对客户和社会负责。
            </p>
          </span>
          <span style="background-color:#f4f4f4">
            <h5>以服务为宗旨</h5>
            <p>
              公司必须建立完善的设计、生产、销售体系，做好售前、售中和售后的全过程服务，不断扩大服务面、提高服务质量。诚信经营、用心服务以此赢得客户的信赖！
            </p>
          </span>
        </div>
      </span>
      <span>
        <h4>企业愿景</h4>
        <p>成为最值得信赖的装配式建筑综合服务商。</p>
      </span>
    </div>

  </div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>

.culture {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top {
   width: 100%;

    }
      .txt {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 3vw;

        span {
          .idea {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            span {
              width: 30%;
              margin: 10px 5px 0;
              padding: 20px 10px ;
              h5 {
                font-size: 16px;
                font-weight: bold;
                color: #000;
              }
              p {
                font-size: 14px;
                line-height: 2;
                color: #434343;
                margin: 0;
              }
            }
          }
          h4 {
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #000;
          }
          p {
            font-size: 14px;
            line-height: 2;
            color: #434343;
            margin-bottom: 30px;
            text-align: justify;
          }
        }
         @media only screen and (max-width: 600px) {
 span{
  .idea{
    display: flex;
    flex-direction: column;
    span{
      width: 90%;
    }

  }
  h4{
    font-size: 4vw;
  }
 }
 }

    }
  }
</style>
