<template>
  <div id="app">
    <topTitle />
    <router-view style="margin-top:70px" />
    <bottomTitle />
  </div>
</template>

<script>
import topTitle from '@/components/TopTitle'
import bottomTitle from '@/components/BottomTitle'
export default {
  name: 'Home',
  components: {
    topTitle,
    bottomTitle
  }
}
</script>
