<template>
  <!-- 案例展示 -->
  <div class="vr">
    <div class="bannerBox">
      <img :src="topBanner">
      <div class="txt">
        <h2>VR-PANORAMA</h2>
        <h3>VR全景</h3>
      </div>
    </div>

    <!-- 正文 -->
    <div class="vrBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="b">
          <span
            class="right"
            :class="{ active: mainindex == null }"
            @click="all"
          >全部</span>
          <template v-for="(item, index) in vrClassList">
            <div
              :key="item.id"
              class="b"
              :class="{ active: index == mainindex }"
              @click="onMain(item.id, index)"
            >
              <span class="right">
                {{ item.vrClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <div class="detail">
        <template v-for="item in vrList">
          <a
            :key="item.id"
            :href="item.url"
            target="_blank"
            class="a"
          >
            <img
              class="up"
              :src="item.photo"
            >
            <div class="down">
              <span class="top">{{ item.name }}</span>
              <div class="button">点击进入</div>
            </div>
          </a>
        </template>
      </div>

      <!-- 分页按钮 -->
      <van-pagination
        v-model="currentPage"
        :page-count="allPage"
        :total-items="total"
        mode="simple"
        @change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { vrbanner, vrClass } from '@/api/home'
import topBanner from '@/assets/banner/vr.jpg'
export default {
  data() {
    return {
      topBanner,
      total: 0,
      pages: 1,
      allPage: 0,
      classId: '',
      limit: 4,
      mainindex: null,
      vrClassList: [],
      vrList: []
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: async function(newV) {
        this.$router.replace({ name: 'Vr' })
        const index = newV.index
        await this.getClass()
        this.$nextTick(() => {
          const id = this.vrClassList[index].id
          this.onMain(id, index)
        })
      }
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    handleCurrentChange(e) {
      this.pages = e
      this.getData()
      document.documentElement.scrollTop = 0
    },
    all() {
      this.mainindex = null
      this.classId = ''
      this.getData()
    },
    onMain(id, index) {
      this.mainindex = index
      this.classId = id
      this.getData()
    },
    async getClass() {
      try {
        const classData = await vrClass()
        this.vrClassList = classData.data
      } catch (error) {
        console.error(error)
      }
    },
    async getData() {
      try {
        const params = {
          vrClassId: this.classId,
          limit: this.limit,
          pages: this.pages
        }
        const infoData = await vrbanner(params)
        this.vrList = infoData.data.records
        this.total = infoData.data.total
        this.allPage = infoData.data.pages
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  border-radius: 5px;
  background-color: #018835;
  color: #fff;
}
.vr {
  width: 100%;
  .bannerBox {
    position: relative;
    width: 100%;
    height: 40vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .txt {
      position: absolute;
      top: 10vw;
      right: 10vw;
      h2 {
        font-size: 9vw;
        color: #ffffff;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 7vw;
        font-weight: bold;
        color: #018835;
        text-align: right;
      }
    }
  }

  .vrBox {
    width: 100%;
    .classBtnBox {
      width: 100%;
      height: 50px;
      border-bottom: 1px #e5e5e5 solid;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 10px 0;

      .b {
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .right {
        height: 100%;
        // width: 100px;
        padding: 5px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;
      }
    }
  }
}

.detail {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .a {
    width: 45%;
    border: 1px solid #eee;
    border-radius: 15px;
    margin-bottom: 10px;
    .up {
      width: 100%;
      object-fit: cover;
    }
    .down {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      span {
        margin: 5px 4vw;
      }
      .top {
        color: #666;
      }
      .button {
        margin-top: 10px;
        border: 1px solid #018835;
        border-radius: 5px;
        font-size: 15px;
        width: 150px;
        padding: 5px;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .a {
      width: 90%;

      span {
        font-size: 4vw;
      }
    }
  }

  .van-pagination {
    width: 90%;
    margin: 0 auto 50px;
  }
}
::v-deep .van-pagination {
  .van-pagination__item {
    color: #323232;
  }
  .van-pagination__item:active {
    background-color: #018835;
  }
}
</style>
