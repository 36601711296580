<template>

  <!-- 伙伴 -->
  <div
    class="friends"
  >
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">STRATEGIC PARTNERS</span>
      <span class="Title">战略合作伙伴</span>
    </div>
    <div class="content">
      <template v-for="item in List">
        <div
          :key="item.id"
          class="partner"
        >
          <router-link
            :to="{ path: '/PartnerContent', query: { id: item.id } }"
          >
            <img
              :src="item.imgUrl"
              class="img"
            >
          </router-link>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import { partner } from '@/api/about'
export default {
  data() {
    return {
      List: []
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      const res = await partner()
      this.List = res.data
    }

  }
}
</script>

<style lang="scss" scoped>
 .friends {
    width: 100%;
    margin-bottom: 10px;
    .content {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    .partner {
        margin: 1px;
        width:30%;
        border: 1px solid #eee;
        .img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>

