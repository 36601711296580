<template>
  <div class="banner">
    <van-swipe
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
    >
      <template v-for="(item, index) in picList">
        <van-swipe-item :key="index">
          <img
            :src="item.url"
            alt=""
            class="bannerImg"
          >
        </van-swipe-item>

      </template>
    </van-swipe>
  </div>
</template>

<script>
import { banner } from '@/api/home'

export default {

  data() {
    return {
      picList: []
    }
  },

  mounted() {

  },
  created() {
    this.banner()
  },

  methods: {
    async banner() {
      try {
        const params = {
          id: this.id
        }

        const res = await banner(params)

        const { img } = res.data[0]
        this.picList = img && img.split(';').map(item => ({ url: item }))
        console.log(this.picList)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background-color: #f6f6f6;
  color: #666666;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .bannerImg {
    width: 100%;
  }
}
</style>
